body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  text-align: right ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: Regular;
    src: url(/static/media/ArbFONTS-Hacen-Algeria.3b30f962.ttf);
}

@font-face {
    font-family: "Hacen";
    src: url(/static/media/Hacen-Algeria.3b30f962.ttf);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

* {
    scroll-behavior: smooth !important;
}

html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden !important;
    font-family: "Hacen" !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container-fluid {
    width: 100%;
    padding-right: 150px !important;
    padding-left: 150px !important;
    margin-right: auto;
    margin-left: auto;
}

.no_pad {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.mobile-push-menu {
    display: none;
}

.mobile-push-menu .nav-link {
    padding: 0px;
    color: white !important;
}

.mobile-push-menu .dropdown-item {
    cursor: pointer;
    text-align: right;
}

.mobile-push-menu .dropdown-item:active,
.mobile-push-menu .dropdown-item:active {
    color: #ef8354;
    z-index: 99999999999 !important;
}

.bm-menu {
    overflow: hidden !important;
}

.bm-overlay {
    z-index: 9888888 !important;
    top: 0;
    right: 0;
}

.bm-burger-button {
    width: 25px;
    margin-right: auto;
    position: relative !important;
    margin-top: 15px;
}

.bm-burger-button i {
    font-size: 25px;
    width: 100%;
    color: #4f5d75;
}

.bm-menu-wrap {
    background-color: #4f5d75;
    left: auto;
    right: 0 !important;
    width: 40% !important;
    top: 0;
    z-index: 9999999999 !important;
}

.bm-item,
.menu-item {
    border: 0 !important;
    color: white !important;
    text-align: right;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.bm-item:active,
.menu-item:active,
.bm-item:hover,
.menu-item:hover,
.bm-item:focus,
.menu-item:focus {
    color: #ef8354 !important;
    text-decoration: none !important;
}

.bm-item-list img {
    margin: auto;
    transform: rotate(90deg);
}

/* .bm-burger-button button{
    width: 60px !important;
    height: 30px !important;
  } */

.bm-item-list {
    margin-top: 40px;
}

.bm-menu-wrap:focus,
.bm-menu:focus,
.bm-item-list:focus,
.bm-item:focus,
.menu-item:focus {
    outline: none;
}

.no-padd {
    padding: 0 !important;
}

/**** Noor ***/

.z-index-top {
    z-index: 8888;
}

.landing .container-fluid {
    width: 100%;
    padding-right: 150px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
}

.person-bg-col {
    background: url(/img/Group831.png) no-repeat;
    background-position: right;
    background-size: cover;
    display: flex;
    padding: 16px !important;
    z-index: 999999999;
    flex-wrap: wrap;
    transform: translate(2%, -4%);
    -ms-transform: translate(2%, -4%);
    -webkit-transform: translate(0%, -2%);
    z-index: -1;
}

.person-bg-col img {
    width: 100%;
    /* object-fit: scale-down; */
    transform: translate(2%, -2%);
    -ms-transform: translate(2%, -2%);
    -webkit-transform: translate(0%, -2%);
    width: 100%;
    height: 100%;
}

/* .landing .scroll-icon {
  background: #ef8354;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10%;

  right: 100px;
  opacity: 0.6;
  cursor: pointer;
  z-index: 999999999999;
} */

.landing .scroll-icon:hover {
    opacity: 1;
}

.landing .MediaUL ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 4%;
    bottom: 5%;
    position: fixed;
    z-index: 999999999;
}

/* header section  */

.header .navbar {
    justify-content: flex-end !important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.navbar-light .navbar-brand {
    position: relative;
}

.header .navbar-light .navbar-toggler {
    z-index: 99999;
}

/* .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  background-color: transparent !important;

} */

.header .navbar-expand-lg .navbar-nav .dropdown-menu {
    text-align: right;
}

.header .dropdown-menu {
    left: auto;
}

.header .navbar-expand-lg .navbar-collapse {
    z-index: 9;
}

.header .dropdown-item {
    padding: 0.25rem 0.5rem;
    font-size: 15px;
}

.header .dropdown-toggle::after {
    margin-right: 7px;
}

.dropdown-item.active,
.dropdown-item:active,
.header .dropdown-item:hover {
    color: #ef8354 !important;
    background-color: transparent !important;
}

.header-logo {
    position: absolute;
    right: 0;
    top: 0;
    /* width: auto; */
    width: 150px;
    height: 220px;
    z-index: 999;
}

.header .active-border-bottom {
    border-bottom: 2px solid #ef8354;
    padding-bottom: 0;
}

.Row-header {
    margin-left: 0 !important;
}

.navbar {
    padding: 0.5rem 0;
}

.header-point-img {
    width: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 40px !important;
    color: #2d3142 !important;
    font-family: Regular;
    font-size: 19px;
}

.MeasureText p {
    text-align: right;
    font-size: 32px;
}

.ApproveTermTest {
    background: #f8fbff;
    padding: 11px;
    border-radius: 8px;
    box-shadow: 1px 1px 9px #86808029;
}

/* Customize the label (the container) */

.ApproveTermTest .container {
    display: block;
    position: relative;
    padding-right: 0;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    text-align: right;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.ApproveTermTest .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.ApproveTermTest .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #f4ad8f6b;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */

.ApproveTermTest .container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.ApproveTermTest .container input:checked ~ .checkmark {
    background-color: #ef8354;
}

/* Create the checkmark/indicator (hidden when not checked) */

.ApproveTermTest .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.ApproveTermTest .container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.ApproveTermTest .container .checkmark:after {
    right: 8px;
    top: 3px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.AllQuestions {
    background: #9e9e9e26;
    padding: 17px;
    border-radius: 6px;
}

.buttonSub {
    display: inline-block;
    border-radius: 4px;
    background-color: #ef8354;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 25px;
    padding: 4px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

.progress-bar {
    background-color: #ef8354 !important;
}

.progress .progress-bar span {
    position: absolute;
    margin-top: 38px;
    color: #4f5d75;
}

.progress .progress-bar:last-child span {
    left: 21px;
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0 !important;
    margin-bottom: 50px;
}

.progress {
    width: 100% !important;
    background-color: #2d31421f !important;
}

.progress .bg-success {
    background-color: #ef8354 !important;
    border-left: 2px solid #323647;
}

.ApproveTermTest a:hover {
    color: #ffffff !important;
}

.question {
    border-bottom: 1px solid #95969a0d;
    margin: 0;
    padding: 0;
}

.showOverlay {
    bottom: 0;
    display: flex;
    font-size: 16px;
    right: calc(50% - 55px);
    margin-bottom: 7px;
    position: absolute;
}

.check {
    bottom: 0;
    display: flex;
    right: calc(50% + 40px);
    margin-bottom: 10px;
    position: absolute;
}

.containerOuter {
    background: white;
    border-radius: 0;
    box-shadow: 2px 6px 20px 2px rgba(10, 10, 120, 0.15);
}

.containerOuter .container {
    position: relative;
    margin: 25px 25px 0;
    overflow: hidden;
    width: 100%;
    padding: 0;
}

.hidden {
    display: none;
}

.entry {
    height: 25px;
    position: absolute;
    width: 160px;
}

.entry:nth-child(2) {
    right: 10px;
    top: 10px;
}

.entry:nth-child(4) {
    right: 10px;
    top: 60px;
}

.entry:nth-child(6) {
    right: 11px;
    top: 108px;
}

.circle {
    border: 2px solid #545556;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    position: absolute;
    transition: border-color 300ms;
    width: 20px;
}

.entry-label {
    cursor: pointer;
    margin-top: 0px;
    padding-right: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -moz-user-select: none;
    width: 500px;
    text-align: -webkit-right;
    padding-right: 36px;
}

.overlay {
    background: #fff;
    -webkit-mask: url(#holes);
            mask: url(#holes);
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAACMCAYAAAAOc+uVAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAItSURBVHic7ZnNjhJBFIVPA0txr0Iyw+tIdOXWaDRufZWZeYbZuMKOUV9Gg+HHGN/BHBcNcSBT1KVOEVycb0mq635UVd9U5zQkif+Y3rkFclhQxYIqFlQZiM8vALQAPgP4AWC1+X0E4ALAFMBzAOPiCixjRfIdyX5g/h7JFyTnJYVKBGckHxQsxJBke2rBK5LF55bdal6fSnCmyO1JhlcyKrhkwbYekBySXNcUfF1L7o7k20jhhsxetxYAJk3T/Kks2EfXmkaHxkXO1MfacgCwmbPNjYsIftV1knzJDYgIfqsgkuJ7bkBE8FcFkRTr3IBzXxay9SOCjyuIFM8dEZxUECmeOyL4tIJIimluQKRRLwFcnqBRDwDMUaFRjwG8quC0zxtk5ACEL6wrksNaZiQfkvwZKRxtM08AfGDgBh2Q6wG4BfAo+sAxXB/xp+6VI3lzTMGSK3/Lgu1mt62fji1W+tH0m+R7dm9iZNVeMnjm9om0mUOs8O+zc47dz84Juh76DJG3NYEqeHLOfVnIYkEVC6pYUMWCKhZUsaCKBVUsqGJBFQuqWFDFgipO3OnEfRcn7qWCTtxTOHFP4cS9Ek7cVZy4qzhxL4ZO3AXoxF2QoxP3DifuW5y4q1hQxYIqFlSxoIoFVSyoYkEVC6pYUMWCKhZUsaCKE3c6cd/FiXupoBP3FE7cUzhxr4QTdxUn7ipO3IuhE3cBOnEX5OjEvcOJ+xYn7ioWVLGgigVV/gKEL4RSyM8Z8wAAAABJRU5ErkJggg==);
    height: 108px;
    pointer-events: none;
    transition: background 300ms;
    width: 40px;
}

.highlight {
    background: #ef8354;
    border-radius: 50%;
    height: 12px;
    right: 14px;
    pointer-events: none;
    position: absolute;
    top: 14px;
    transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
    transform: translateY(-50px);
    width: 12px;
}

.hidden:nth-child(1):checked ~ .highlight {
    transform: translateY(0);
}

.hidden:nth-child(3):checked ~ .highlight {
    transform: translateY(50px);
}

.hidden:nth-child(5):checked ~ .highlight {
    transform: translateY(100px);
}

.hidden:nth-child(1):checked + .entry .circle {
    border-color: #ef8354;
}

.hidden:nth-child(3):checked + .entry .circle {
    border-color: #ef8354;
}

.hidden:nth-child(5):checked + .entry .circle {
    border-color: #ef8354;
}

.check:checked ~ .containerOuter .overlay {
    background: #ecf0f4;
}

.containerOuter h3 {
    text-align: right;
    padding-right: 25px;
    padding-top: 23px;
    margin-bottom: 0;
    font-size: 22px;
}

html {
    scroll-behavior: smooth;
}

.TotalProgress .progress {
    margin-bottom: 3px;
}

.TotalProgress {
    padding: 0 21px;
}

.TotalProgress .ProgressRight h5 {
    text-align: right;
    margin-bottom: 34px;
    color: #4f5d75;
}
.TotalProgress .ProgressRight,
.TotalProgress .ProgressLeft {
    padding: 0;
}

.TotalProgress .ProgressLeft .progress-bar {
    background-color: #2d3142 !important;
}

.TotalProgress .ProgressLeft h5 {
    text-align: left;
    margin-bottom: 34px;
    color: #4f5d75;
}

.TotalProgress .ProgressRight .progress {
    margin-bottom: 3px;
    transform: scaleX(-1);
}

.TotalProgress .ProgressRight .progress-bar {
    transform: scaleX(-1);
}

.ExamImg {
    margin-top: -170px;
}

.buttonSub span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.buttonSub span:after {
    content: "\00bb";
    position: absolute;
    opacity: 0;
    top: -3px;
    left: -160px;
    transition: 0.5s;
}

.buttonSub:hover span {
    padding-left: 25px;
}

.buttonSub:hover span:after {
    opacity: 1;
    left: 0;
}

.ExamStart {
    background-image: url(/img/Group963.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 103px;
}

.ExamStartResult {
    background-image: url(/img/Group963.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -62px;
    height: 143px;
    padding-top: 32px;
    font-size: 32px;
    color: #fff;
}

.react-quiz-container .tag-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: none !important;
}

.MeasureStart {
    padding: 29px 0;
    font-size: 33px;
    color: #fff;
}

.MeasureStart ul li h4 {
    color: #2d3142;
}

.MeasureStart ul {
    list-style: none;
    display: -webkit-inline-box;
    width: 100%;
    padding: 0;
    margin: 0;
}

.ShareRow ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
}

.MeasureStart ul li {
    width: 33.3%;
    margin: 5px;
}

.MeasureStart ul li .TestBox {
    background: #fff;
    padding: 6px;
    border-radius: 7px;
    box-shadow: 2px 2px 7px #2d314240;
    line-height: 2.5;
    margin: 22px 0 -75px 0;
}

.MeasureResultText ul {
    list-style: inside;
    display: unset;
}
.tearms {
    min-height: 574px  ;
}

.react-quiz-container {
    margin: 0 !important;
    width: 100% !important;
    text-align: right;
    background: #9e9e9e14;
    border-radius: 7px;
    box-shadow: 1px 2px 4px #d2cfcf;
    padding: 12px;
}

.MeasureStartEnd .Box {
    background: #fff;
    padding: 6px;
    border-radius: 10px;
    margin-bottom: 29px;
    box-shadow: 0px 0px 7px #cacaca7a;
}

.MeasureStartEnd .Box .BoxTitle {
    display: flex;
    justify-content: space-around;
    padding-top: 13px;
}

.MeasureStartEnd .Box .BoxTitle h3 {
    color: #4f5d75;
}

.MeasureStartEnd .Box .BoxTitle h4 {
    color: #ef8354;
    font-size: 32px;
}

.Buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.EmailInputGroup {
    direction: ltr;
}

.MeasureResultText p {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    text-align: right;
    color: #2d3142;
}

.EmailSubs {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 7px #cacaca7a;
    text-align: right;
    padding: 13px;
    margin-top: 67px;
}

.EmailSubs .buttonSub span:after {
    content: "\00AB";
}

.slick-dots li {
    width: 20px !important;
}

.progressBarResult h4 {
    color: #ef8354;
    text-align: right;
}

.Progresses {
    display: flex;
}

.Progresses span {
    font-size: 17px;
}

.Buttons .next {
    background: #ef8354;
    padding: 6px 24px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.ShareMeasure {
    text-align: right;
    width: 100%;
    background: #c1bbbb70;
    padding: 0 8px;
    border-radius: 10px;
    display: flow-root;
    justify-content: space-between;
    color: #2d3142;
    padding-bottom: 10px;
}

.ShareMeasure a {
    color: #2d3142;
    text-decoration: none;
    font-size: 15px;
    padding-top: 10px;
}

#sharethis-1629267068764.st-has-labels .st-btn {
    min-width: auto !important;
}

#sharethis-1629267130507 .st-btn[data-network="whatsapp"] > span {
    display: none !important;
}

.ShareMeasure a:hover {
    color: #ef8354;
    text-decoration: none;
    transition: 0.5s;
}

.ShareRow p {
    text-align: right;
}

.ShareRow ul li a {
    padding: 5px 36px;
    border-radius: 4px;
    color: #fff !important;
    cursor: pointer;
}

.ShareRow ul li:first-child a {
    background: #ef8354;
}

.ShareRow ul li:last-child a {
    background: #2d3142;
}

.ShareMeasure spans {
    background: #ef8354;
    padding: 0px 15px 2px 15px;
    border-radius: 12px;
}

.Buttons .next:hover {
    background: #f5f5f5;
    color: #ef8354;
    transition: 0.5s;
    box-shadow: 1px 1px 7px #68696d45;
}

.ExamWrapper {
    background: #e9ecef4f;
    margin: 0;
    padding: 0;
    margin-bottom: -19px;
    padding-bottom: 63px;
}

.Buttons .prev {
    background: #2d3142;
    padding: 6px 24px;
    border-radius: 5px;
    color: #fff;
}

.Buttons .prev:hover {
    background: #d2cfcf;
    color: #2d3142;
    transition: 0.5s;
    cursor: pointer;
    box-shadow: 1px 1px 7px #68696d45;
}

.submitButt {
    text-align: left;
}

.EmailSubs h2 {
    font-size: 20px;
    color: #ef8354;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ef835461 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(239 131 84 / 12%) !important;
}

.EmailSubs .form-control {
    color: #495057;
    background-color: #9e9e9e2b;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px !important;
    text-align: right;
}

.TextDescription {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EmailSubs .buttonSub {
    display: inline-block;
    border-radius: 4px;
    background-color: #ef8354;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    padding: 4px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 0px 22px;
    text-decoration: none;
}

.overflow-clip {
    text-overflow: clip;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
}

.overflow-string {
    text-overflow: " [..]";
}
.slick-slide img {
    display: block;
    width: 100% !important;
    max-height: 100% !important;
    object-fit: fill;
    border-radius: 20px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #ef8354 !important;
}

.croosIconMenu {
    color: white;
}

/* end header section  */

/* landing page section  */

.landing {
    position: relative;
}

.landing .row {
    margin-left: 0 !important;
}

.landing-details {
    margin-top: 130px;
    margin-right: auto;
    text-align: right;
}

.landing-details p,
.landing-details h6 {
    color: #4f5d75;
    line-height: 1.7;
}

.landing-details h2 {
    color: #ef8354;
    /* text-align: center; */
    font-size: 70px;
    margin: 20px 0;
}

.know-more-button {
    border: 0;
    padding: 5px 50px;
    background-color: #4f5d75;
    color: white;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: 0px 0px 5px 0px #4f5d75;
    transition: ease-in-out 0.3s;
}

.know-more-button:hover {
    background-color: #ef8354;
    transition: ease-in-out 0.3s;
}

.know-more-button:focus {
    outline: none !important;
}

.person-bg-col {
    padding: 0 !important;
    margin-left: -25px !important;
}

.social-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    right: 45px;
    bottom: -140px;
}

.social-header li {
    display: inline-block;
}

.social-header div a i {
    color: #4f5d75;
}

.social-header div div {
    width: 2px;
    height: 85px;
    background-color: #4f5d75;
}

.landing .scroll-icon img {
    width: 18px;
}

/* .mobile-landing-bg-person{
  display: none;
  } */

.MediaUL ul i {
    color: #4f5d75;
    font-size: 25px;
}

.MediaUL ul i:hover {
    color: #ef8354;
}

.MediaUL ul:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 90px;
    background-color: #4f5d75;
    right: 10px;
    bottom: auto;
}

/* carousel cover flow  */

.carousel-cover-flow {
    margin: 40px 0;
    position: relative;
}


.carousel-cover-flow-New {
    margin: 40px;
    position: relative;
    
}

.slick-dots li button:before { 
    color: #ef8354 !important;
    font-size: 20px !important;
}

.half-circle {
    position: absolute;
    bottom: -100px;
    right: 0;
    z-index: -111111;
    width: 10%;
}

.coverflow__container__1P-xE {
    direction: ltr;
    background: transparent !important;
}

.coverflow__figure__3bk_C {
    height: 100% !important;
    max-height: 195px;
    box-shadow: none !important;
    -webkit-box-reflect: below 1px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1) 0%, rgba(
                    0,
                    0,
                    0,
                    0
                )
                0%, rgba(0, 0, 0, 0)) !important;
}

.coverflow__cover__25-7e {
    box-shadow: none !important;
    height: 100%;
    object-fit: contain;
}

.coverflow__cover__25-7e:focus,
.coverflow__figure__3bk_C:focus {
    outline: none !important;
}

.coverflow__stage__14oqC {
    perspective: none !important;
    max-height: 350px !important;
}

/* end carousel cover flow  */

/* our services section  */

.OurServices,
.Training {
    text-align: right;
}

.OurServices {
    margin: 80px 0;
}

.courseDetails-page .ourServices-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ourServices-title {
    margin-top: 70px;
}
.ourServices-title h3 {
    color: #4f5d75;
    /* -webkit-text-decoration-color: #ef8354;
  text-decoration-color: #ef8354; */
    font-size: 30px;
    border-bottom: 1px solid #ef8354;
    border-width: 2px;
    width: -webkit-max-content;
    width: max-content;
    line-height: 0.5;
    margin-bottom: 0px;
}

.ourServices-title h3:after {
    height: 2px;
    width: 30px;
    background-color: #ef8354;
    content: "";
    position: absolute;
    bottom: 0px;
    margin: 0px 10px;
}

.ourServices-paragraph p {
    color: #4f5d75;
    margin-top: 20px;
}

.ourService-tab-section {
    text-align: center;
    width: 80%;
}

.tab-icon {
    padding: 15px;
    border: 2px solid #ef8354;
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    transition: ease-in-out 0.5s;
}

.tab-icon img {
    width: 55px;
    height: 55px;
    transition: ease-in-out 0.5s;
    object-fit: scale-down;
}

.ourService-tab-section .routService {
    padding: 45px 20px 35px 20px;
    background-color: #f6d2c3;
    border-radius: 20px;
    color: #4f5d75;
    margin-top: -35px;
    transition: ease-in-out 0.5s;
    min-height: 271px;
}

.ourService-tab-section button {
    border-radius: 5px;
    color: 0;
    padding: 5px 30px;
    background-color: #ef8354;
    color: white;
    border: 0;
    margin: auto;
    margin-top: -20px;
    display: block;
    transition: ease-in-out 0.5s;
}

.ourService-tab-section button:focus {
    outline: none;
}

.ourService-tab-section button:hover {
    background-color: #4f5d75;
    transition: ease-in-out 0.5s;
}

.ourService-hover:hover .tab-icon img {
    filter: brightness(60%) saturate(0) hue-rotate(0);
}

.ourService-hover:hover .tab-icon {
    border: 2px solid #4f5d75;
}

.ourService-hover:hover .routService {
    background-color: #4f5d75;
    color: white;
    transition: ease-in-out 0.5s;
    cursor: pointer;
}

.ourService-hover h4 {
    margin-bottom: 15px;
    color: #4f5d75;
    transition: ease-in-out 0.5s;
}

.ourService-hover:hover h4 {
    color: white;
    transition: ease-in-out 0.5s;
}

/* end our services section  */

/* training section  */
.Training {
    margin: 159px 0;
    position: relative;
}
.Training .ourServices-paragraph {
    margin-bottom: 45px;
}

.directionRtl {
    direction: rtl !important;
}

.react-multi-carousel-list {
    direction: ltr;
    width: 90%;
    margin: auto;
    position: static !important;
}

.react-multi-carousel-track {
    z-index: 99;
}

.react-multi-carousel-item {
    overflow: hidden !important;
}

.training-img .training-img-img {
    /* width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 7px -3px #4F5D75;
   */
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 7px -3px #4f5d75;
    /* min-height: 350px; */
    object-fit: cover;
    height: 400px;
}

.training-img {
    position: relative;
    width: 95%;
    padding-top: 2px;
    display: inline-block;
}

.training-img p {
    background-image: linear-gradient(#ef835400, #ef8354e0);
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    color: white;
    width: 100%;
    align-items: flex-end;
    padding-bottom: 35px;
    font-family: system-ui;
    font-size: 18px;
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 10px;
    height: 30%;
    border-bottom-right-radius: 10px;
}

.Training .commingSoon-label {
    padding: 0px 5px 5px 0;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px dashed #4f5d75;
    border-radius: 50%;
    border-top: 0;
    border-left: 0;
    border-style: dashed;
}

.Training .commingSoon-label div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #4f5d75;
    border-radius: 50%;
    border-top-left-radius: 0;
}

.Training .commingSoon-label img {
    box-shadow: none;
}

.Training .commingSoon-label span {
    color: white;
    position: absolute;
    font-size: 22px;
}

.react-multiple-carousel__arrow--right {
    right: 0 !important;
}

.react-multiple-carousel__arrow--left {
    left: 0 !important;
}

.react-multiple-carousel__arrow--left:focus,
.react-multiple-carousel__arrow--right:focus,
.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
    background: transparent !important;
    outline: none !important;
}

.react-multiple-carousel__arrow--left:hover,
.react-multiple-carousel__arrow--right:hover {
    background-color: transparent !important;
}

.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow::before {
    color: #ef8354 !important;
    z-index: 99 !important;
    font-size: 35px !important;
}

.react-multi-carousel-dot-list {
    bottom: -30px !important;
}

.react-multi-carousel-dot button {
    background-color: #f6dcd3 !important;
    border: 0 !important;
    width: 10px !important;
    height: 10px !important;
}

.react-multi-carousel-dot--active button {
    background-color: #ef8354 !important;
    transform: scale(1.3);
}

.Training .cfKeGQ {
    z-index: 99;
}

.Training .queries-circle {
    top: -100px;
}

.Home .Training .queries-circle {
    top: -300px;
    z-index: -1;
}

.Training .Training-col-know-more-buttom {
    text-align: center;
    margin-top: 40px;
}

/* end training section  */

/* queries section  */

.queries {
    background-color: #eef2f7;
    padding: 100px 0;
    position: relative;
}

.queries .ourServices-title,
.queries .ourServices-paragraph {
    text-align: right;
}

.queries-info {
    margin-top: 30px;
    position: relative;
}

.queries-info h3 {
    color: #ef8354;
    margin: 20px 0;
}

.queries-info span {
    color: #4f5d75;
}

.queries-video {
    position: relative;
    z-index: 99;
}

.queries-video img:first-child {
    width: 100%;
}

.LoactionName {
    min-height: 58px;
}
.queries-video img:last-child {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
}

.queries-circle {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
}

.queries-circle img {
    height: 100%;
    padding: 25px 0;
}

.queries-points-img {
    position: absolute;
    top: 55px;
    left: 25%;
    width: auto;
}

.queries-points-img img {
    width: 80%;
}

.queries .know-more-button {
    margin-top: 60px;
}

.queries-col-know-more-buttom {
    text-align: right;
}

.queries .modal-video {
    z-index: 99999999999999;
    background-color: rgba(0, 0, 0, 0.9);
}

.queries .modal-video-close-btn:focus {
    outline: none !important;
}

/* end queries section */

/* books section  */

.books {
    margin-top: 100px;
    min-height: 579px;
}

.books .books-carousel {
    width: 80%;
    height: 350px;
    margin: 0 auto;
    direction: ltr;
    margin-top: 100px;
}
.books .ourServices-title,
.books .ourServices-paragraph {
    text-align: right;
}

.books .ourServices-paragraph {
    color: #4f5d75;
}

.books .ourServices-title h3 {
    margin-bottom: 25px;
    cursor: pointer;
}

/* .books .ourServices-title h3:after {
  bottom: 4px;
} */

.books .css-1fzpoyk {
    top: 20% !important;
    transform-origin: 375% 60% !important;
    overflow: hidden;
}

/* .books .css-1fzpoyk img {
  width: 150% !important;
  max-width: 150% !important;
} */

.books .css-1fzpoyk img {
    cursor: pointer;
}

.books .css-1qzevvg {
    width: 80% !important;
    bottom: 75%;
    z-index: 9999;
}

.books-arrow-right {
    position: absolute;
    right: 20%;
    bottom: auto;
    cursor: pointer;
    top: 20%;
    z-index: 99;
}

.books-arrow-left {
    position: absolute;
    left: 20%;
    bottom: auto;
    cursor: pointer;
    top: 17%;
    z-index: 99;
}

.testimonials-arrow-right,
.testimonials-arrow-left {
    top: calc(50% - 24px);
    position: absolute;
    bottom: auto;
    cursor: pointer;
    z-index: 99;
}

.testimonials-arrow-right {
    right: 10%;
}

.testimonials-arrow-left {
    left: 10%;
}

.books .css-1qzevvg {
    display: none !important;
}

/* end books section  */

/* Recommendations section  */

.recommendations {
    margin: 80px 0;
    position: relative;
}

.recommendations .css-1fzpoyk {
    width: 60%;
    /* width: fit-content; */
}

/* .recommendations .ourServices-paragraph {
    margin-bottom: 30px;
}

.recommendations .image-gallery-slides {
    white-space: break-spaces !important;
    line-height: 1.2;
}
.recommendations .image-gallery-slide {
    cursor: auto;
}
.image-gallery-slide.center:focus {
    outline: none !important;
} */

.recommendations-card {
    width: 100%;
    margin: auto;
    position: relative;
}

.recommendations .Employee-info p,
.recommendations .Employee-info h4 {
    color: #4f5d75;
}

.recommendations .Employee-info h6 {
    font-size: 14px;
    color: #bfc0c0;
}

.recommendations .Employee-info {
    padding: 30px 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px silver;
    background-color: white;
    /* width: 90%; */
    margin: 2px auto;
}

.recommendations .Employee-info img {
    height: 50px;
    margin: auto;
}

/* .recommendations .Employee-info-bg {
    height: 82%;
    position: absolute;
    width: 96%;
    box-shadow: 0px 0px 6px 0px silver;
    z-index: -1111111;
    top: 8%;
    border-radius: 5px;
    left: 2%;
} */

.recommendations .image-gallery {
    direction: ltr;
}

.recommendations .image-gallery-thumbnail {
    transition: ease-in-out 0.7s;
    border: 0 !important;
}

.recommendations .image-gallery-thumbnail {
    transform: scale(0.6);
    opacity: 0.8;
}

.recommendations .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    border: 0 !important;
    transform: scale(1);
    opacity: 1;
    margin-top: 6px;
}

.recommendations .image-gallery-thumbnails .image-gallery-thumbnails-container {
    min-height: 126px;
}

.recommendations .image-gallery-icon {
    filter: none !important;
}

.recommendations .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    color: #ef8354;
}

.swal-button:not([disabled]):hover {
    background-color: #ef8354;
}

.swal-button {
    background-color: #ef8354;
}

.thumb-employee-img {
    border: 2px solid #ef8354;
    /* display: inline-block; */
    border-radius: 50%;
    background-color: white;
    transition: ease-in-out 0.7s;
    /* width: 100%; */
    width: 100px;
    height: 100px;
    object-fit: cover !important;
    margin: auto;
    margin-bottom: -20px !important;
    position: relative;
}

.recommendations .queries-circle {
    top: -200px;
}

.recommendations .header-point-img {
    bottom: 0;
    top: auto;
}

.recommendations .queries-circle {
    z-index: -1;
}

/* end Recommendations section  */

/* contact us section  */

.full-height {
    min-height: 550px;
}

.contactUs {
    text-align: right;
    position: relative;
    padding-bottom: 100px;
    margin: 80px 0;
    /* min-height: 550px; */
}

.contactUs-title h3 {
    color: #4f5d75;
    -webkit-text-decoration-color: #ef8354;
            text-decoration-color: #ef8354;
    font-size: 30px;
    border-bottom: 1px solid #ef8354;
    border-width: 2px;
    width: -webkit-max-content;
    width: max-content;
    line-height: 0.5;
    margin-bottom: 0px;
}

.contactUs-title h3::after {
    height: 2px;
    width: 30px;
    background-color: #ef8354;
    content: "";
    position: absolute;
    bottom: 0px;
    margin: 0px 10px;
}

.contactUs .contactUs-form {
    margin-top: 30px;
    padding: 20px 40px;
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 3px 0px silver;
    border-radius: 5px;
}

.contactUs-form textarea {
    height: 140px;
}

.contactUs-form textarea,
.contactUs-form input {
    background-color: #edeef1;
    border: 0;
    padding: 8px 20px;
    border-radius: 9px;
    width: 100%;
    margin-bottom: 20px;
    resize: none;
}

.contactUs-form input:focus,
.contactUs-form input:active,
.contactUs-form textarea:focus {
    outline: none !important;
}

.contactUs-form .radio-input {
    width: auto;
    margin-bottom: 0px;
}

/*  cheack box style  */

/* The container */

.contactUs-form .container-input {
    margin-left: 10px;
    display: inline;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.contactUs-form .container-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.contactUs-form .checkmark {
    position: absolute;
    top: 0;
    right: 10px;
    height: 17px;
    width: 17px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */

.contactUs-form .container-input:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.contactUs-form .container-input input:checked ~ .checkmark {
    background-color: #ef8354;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.contactUs-form .container-input input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.contactUs-form .container-input .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/*  cheack box style  */

.contactUs-form textarea:-ms-input-placeholder, .contactUs-form input:-ms-input-placeholder {
    color: #c5c9d1;
}

.contactUs-form textarea::placeholder,
.contactUs-form input::placeholder {
    color: #c5c9d1;
}

.contactUs-form span {
    margin-right: 20px;
    font-size: 14px;
    color: #616e83;
    border-radius: 50%;
    display: inline;
}

.contactUs-form .know-more-button {
    display: block;
    background-color: #ef8354;
    margin-top: 20px;
    transition: ease-in-out 0.3s;
}

.contactUs-form .know-more-button:hover {
    background-color: #4f5d75;
    transition: ease-in-out 0.3s;
}

.contactUs-form .know-more-button:focus {
    outline: none !important;
}

.contactUs .invalidEmail,
.WhyChooseUs .invalidEmail {
    border: 1px solid red;
}

.contactUs-socialMedia {
    width: 100%;
}

.contactUs-socialMedia ul {
    list-style: none;
    padding: 10px;
    background-color: #ef8354;
    border-radius: 10px;
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
}

.contactUs-socialMedia ul li {
    color: white;
    padding: 7px;
    margin: auto;
}

.contactUs-socialMedia ul li i:hover {
    color: #4f5d75;
    transition: ease-in-out 0.3s;
}

.contactUs-socialMedia ul li i {
    color: white;
    transition: ease-in-out 0.3s;
}

.whyChooseUs-contact .header-point-img,
.contactUs .header-point-img {
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;
    z-index: -1;
}

/* .WhyChooseUs .scroll-icon{
  bottom: 5%;
} */

.WhyChooseUs .carousel {
    margin-top: 25px;
}

.WhyChooseUs .carousel-control-prev-icon,
.WhyChooseUs .carousel-control-next-icon {
    /* display: none ; */
    background: none;
    top: -15px !important;
    height: 75px !important;
}

.WhyChooseUs .carousel-control-next-icon::after {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 45px;
}

.WhyChooseUs .carousel-control-prev-icon::after {
    content: "\f104";
    font-family: FontAwesome;
    font-size: 45px;
}

/* end contact us section  */

/* footer section  */

.footer {
    background-color: #2d3142;
    color: white;
    padding: 20px 0 0px 0;
    margin-top: 20px;
}

.footer-right {
    text-align: right;
    place-self: center;
}

.footer-list {
    list-style: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.footer-list li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
}

.footer-list li a:hover {
    color: #ef8354;
}

.footer-Visitors {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.footer .numOf-Visitors {
    font-size: 45px;
    margin-right: 20px;
}

.footer-left {
    text-align: left;
}

.footer-left img {
    margin-bottom: 15px;
    width: 130px;
}

.footer-left p {
    font-size: 14px;
}

/* end footer section  */

/* scroll down icon  */

.MediaUL ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 4%;
    bottom: 14%;
    position: fixed;
    z-index: 999999999;
}

.styles_scroll-to-top__2A70v svg {
    /* display: none; */
    fill: white !important;
    width: 20px !important;
}

.styles_scroll-to-top__2A70v {
    background: #ef8354 !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: 0 !important;
    opacity: 0.6 !important;
    bottom: 5% !important;
    right: 100px !important;
    cursor: pointer !important;
    z-index: 9999999999 !important;
    transition: ease-in-out 0.2s !important;
}

.scroll-icon {
    background: #ef8354;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /* bottom: 2%; */
    bottom: 40px;
    right: 100px;
    opacity: 0.6;
    cursor: pointer;
    z-index: 9999999999;
}

.styles_scroll-to-top__2A70v:hover,
.styles_scroll-to-top__2A70v:focus,
.scroll-icon:hover {
    opacity: 1 !important;
    outline: none !important;
    transition: ease-in-out 0.2s !important;
}

.scroll-icon img {
    width: 20px;
}

/* scroll down icon  */

/* Training page section  */

/* landing section  */

.PlayVideoImg {
    width: auto !important;
}
.Training-landing-page {
    height: 590px;
    position: relative;
}

.Training-page .queries {
    padding: 30px 0;
}

.Training-landing-page-img {
    /* background: url(/img/Group1831.png) no-repeat; */
    background-repeat: no-repeat;
    height: 636px;
    width: 100%;
    background-size: cover !important;
}

.training-landing-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
    color: #2d3142;
}

.training-landing-card {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px 0px grey;
    cursor: pointer;
}

.training-landing-card img {
    width: 50px;
    height: 50px;
    margin: 0 10px;
}

.training-landing-card span {
    font-size: 20px;
    color: #4f5d75;
}

/* end  landing section  */

.personal-training .queries-info {
    padding: 30px 0;
    background-color: white;
    box-shadow: 0px 0px 12px 0px silver;
    border-radius: 5px;
}

.all-personal-training .queries {
    background-color: white;
}

/* personal-training  */

.Home .queries-info img {
    height: 50px;
    object-fit: scale-down;
}

.queries-info img,
.personal-training .queries-info img {
    width: 50px;
    margin-bottom: 20px;
}

.all-training img {
    height: 50px;
}

.personal-training .queries-info p {
    font-size: 15px;
    color: #4f5d75;
}

.Pachages .ourServices-title,
.WhyChooseUs .ourServices-title,
.planOfCourse .ourServices-title,
.Training-page .ourServices-title {
    display: flex;
    align-items: baseline;
    /* margin-bottom: 20px; */
}

.Publications .ourServices-title img,
.Training-page .ourServices-title img {
    width: 50px;
}

.Publications .ourServices-title h3,
.Pachages .ourServices-title h3,
.WhyChooseUs .ourServices-title h3,
.Training-page .ourServices-title h3 {
    margin: 0 15px;
    /* font-size:41px */
    font-size: 30px;
}

.Pachages .ourServices-title h3::after,
.WhyChooseUs .ourServices-title h3::after,
.Training-page .ourServices-title h3:after {
    /* bottom: 29px; */
    bottom: 0px;
}

.justify-content-space-between {
    justify-content: space-between;
}

.whyChooseUs-contact {
    margin: 40px 0;
    position: relative;
}

.whyChooseUs-contact .contactUs-title h3 {
    margin-bottom: 40px;
}

.whyChooseUs-contact .contactUs-title h3::after {
    bottom: auto;
    top: 15px;
}

.whyChooseUs-contact .contactUs-form {
    padding: 30px 60px;
    background-color: white;
    box-shadow: 0 0 6px 0px silver;
    border-radius: 8px;
    margin-bottom: 20px;
}

.contact-img-whyChooseUs img {
    /* width: 80%; */
    width: 100%;
    border-radius: 8px;
    height: 456px;
}

.whyChooseUs-contact .contactUs-form textarea {
    height: 116px;
}

.whyChooseUs-contact .contactUs-socialMedia {
    width: 100%;
}

.whyChooseUs-contact .contactUs-socialMedia ul {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    font-size: 25px;
    /* text-align: center; */
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.whyChooseUs-contact .contactUs-socialMedia ul li {
    padding: 0px;
}

.whyChooseUs-contact .contactUs-socialMedia ul li i {
    color: #ef8354;
}

.whyChooseUs-contact .contactUs-socialMedia ul li:hover i {
    color: #4f5d75;
}

.whyChooseUs-contact .contactUs-form .know-more-button {
    padding: 9px 50px;
    margin: auto;
    margin-top: 20px;
}

/* end personal-training  */

/* direct training  */

.direct-training {
    margin: 100px 0;
}

.direct-training .queries {
    background-color: white;
}

.direct-training .ourServices-paragraph {
    margin-bottom: 70px;
}

/* end direct training  */

/* next course  */

.next-course-paragraph {
    text-align: right;
    color: #4f5d75;
    margin-bottom: 80px;
}

.next-course-paragraph h2 {
    font-size: 37px;
}

.course-details {
    padding: 20px 0;
    background-color: white;
    box-shadow: 0px 0px 12px 0px silver;
    border-radius: 5px;
    margin-bottom: 20px;
    height: 90%;
    /* height: 84%; */
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

.queries .course-details {
    height: 84%;
}

.course-details img {
    width: 40px;
}

.direct-training .course-details p {
    margin: 25px 0;
    margin-bottom: -5px;
}

.price-before-discount {
    text-decoration: line-through !important;
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
}

.course-details p {
    margin: 5px 0;
    color: #4f5d75;
    font-size: 22px;
    margin-bottom: 0px;
}

.course-details span,
.course-details span p {
    font-size: 13px;
    color: #bfc0c0;
}

.course-details .course-descount {
    color: #ef8354;
}

.course-details-special {
    display: flex;
    padding: 0;
    align-items: center;
    /* margin-bottom: 11px; */
}

.course-details-special .course-details-special-img {
    width: 100px;
    height: 100px;
    background-color: #ef8354;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.direct-training .course-details-special p {
    margin: 0 20px;
}

.course-details-special p {
    text-align: right;
}

.course-details-special-text {
    text-align: right;
}

.Training-page .header-point-img {
    width: auto;
    position: absolute;
    right: -200px;
    bottom: 100px;
    top: auto;
}

.Training-page .course-details:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
}

/* end next course  */

.line-1 {
    position: relative;
    /* top: 50%;   */
    /* width: 24em; */
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    /* transform: translateY(-50%);     */
}

/* Animation */

.anim-typewriter {
    animation: typewriter 3s steps(44) 0s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
    from {
        width: 0;
    }
    to {
        width: 7.9em;
    }
    /* to{width: 405px;} */
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    to {
        border-right-color: transparent;
    }
}

/* .iamfxf:hover{
  transform: scale(1.2);
  transition: ease-in-out 0.5s;
} */

/* end  Training page section  */

/* training scheme page section  */

/* landing page  */

.Training-scheme-landing-page-img {
    background: url(/img/Group8831.png) no-repeat;
    height: 775px;
    width: 100%;
    background-size: cover;
}

/* end landing page  */

.training-scheme-personal-training .queries {
    background-color: white;
}

.structure-training .personal-training .queries .ourServices-title {
    justify-content: center;
}

.structure-training .personal-training .queries .ourServices-title h3 {
    text-decoration: none;
}

.structure-training .personal-training .queries .ourServices-title h3::after {
    content: none;
}

.structure-training .personal-training .queries .ourServices-paragraph {
    text-align: center;
}

.all-personal-training .queries-info {
    padding: 30px 0;
    background-color: white;
    box-shadow: 0px 0px 12px 0px silver;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

.personal-online .ourServices-title {
    justify-content: center;
}

.personal-online .ourServices-title h3::after {
    display: none !important;
}

.all-personal-training .queries-info:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
}

.all-personal-training .queries-info p {
    font-size: 15px;
    color: #4f5d75;
}

.all-personal-training .queries-info img {
    width: 50px;
    margin-bottom: 20px;
}

.structure-training .personal-training .queries-info {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    color: #bfc0c0;
}

.structure-training .personal-training .select-axis {
    align-items: inherit;
}

.structure-training .personal-training .numOfSessions img {
    width: 35px;
}

.structure-training .personal-training .queries-info h6 {
    color: #ef8354;
    margin-bottom: 25px;
}

.structure-training .personal-training .queries-info p {
    font-size: 13px;
    color: #bfc0c0;
}

.structure-training .personal-training .queries-info .img-lamp {
    width: 120px;
}

.structure-training .personal-training .queries-info div {
    margin-right: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
}

.structure-training .personal-training .queries-info div img {
    width: 25px;
    margin: 0;
}

.structure-training .personal-training .queries-info span {
    color: #bfc0c0;
    font-size: 13px;
    margin: 0 10px;
}

.structure-training .personal-training .queries .queries-col-know-more-buttom {
    text-align: center;
}

.structure-training .personal-training .queries .know-more-button {
    width: 25%;
    padding: 10px;
}

.structure-training .personal-training .queries .know-more-button:focus {
    outline: none !important;
}

.structure-training .queries-points-img {
    position: absolute;
    top: -45px;
    left: 0;
    width: auto;
}

.structure-training .queries-points-img img {
    width: 100%;
}

.structure-training .queries-points-img-cut {
    position: absolute;
    bottom: 55px;
    right: 0;
    width: auto;
}

/* end training scheme page section  */

/* course details section  */

.courseDetails-page .queries {
    background-color: white;
}

.courseDetails-page .course-details-special .course-details-special-img {
    background-color: white;
}

.courseDetails-page .course-details-special .course-details-special-img img {
    width: 60px;
    height: 60px;
    object-fit: scale-down;
}

.courseDetails-page .justify-content-space-between {
    align-items: center;
}

.course-tag {
    color: #2d3142;
    font-weight: bold;
    background-color: #ef8355;
    padding: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    min-width: 80px;
}

/* .courseDetails-page .course-details-special div:last-child{


} */

.courseDetails-page .ourServices-title h3 {
    margin: 40px 0;
    /* font-size:50px; */
    text-decoration: none;
}

.courseDetails-page .ourServices-title h3::after {
    content: none;
}

.courseDetails-page .course-details img {
    height: 40px;
    object-fit: scale-down;
    margin-bottom: 10px;
}

.courseDetails-page .queries-points-img {
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
}

.courseDetails-page .queries-points-img img {
    width: auto;
}

.whatLearn-forWho {
    margin-top: 50px;
}

.whatLearn-forWho .course-details {
    padding: 40px;
    cursor: auto;
}

.whatLearn-forWho .ourServices-paragraph p {
    margin-bottom: 55px;
}

.whatLearn-forWho .course-details p {
    margin-bottom: 1rem;
}

.planOfCourse .queries {
    background-color: #eef2f7;
}

.planOfCourse .ourServices-title h3 {
    /* text-decoration: underline; */
    color: #4f5d75;
    /* text-decoration-color: #ef8354; */
}

.planOfCourse .ourServices-title h3 {
    margin: 0 15px;
    /* font-size:41px */
}

.ourServices-title img {
    width: 40px;
    height: 40px;
    object-fit: scale-down;
}

.accordion-course {
    margin-top: 25px;
}

.accordion-course .accordion > .card {
    border-radius: 0;
    margin-bottom: 5px;
}

.accordion-course .accordion > .card > .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ced4dd;
    padding: 9px 20px;
    cursor: pointer;
}

.accordion-course .accordion > .card > .card-header div {
    display: flex;
    align-items: center;
}

.accordion-course .accordion > .card > .card-header div h4 {
    color: #4f5d75;
    font-size: 15px;
    margin: 0 15px;
}

.accordion-course .accordion > .card > .card-header div span {
    color: #4f5d75;
    font-size: 12px;
    margin: 0 20px;
}

.accordion-course .accordion > .card > .card-header div img {
    width: 14px;
}

.accordion-course .accordion > .card > .watch-done {
    background-color: #efdcd6;
}

.accordion-course .accordion > .card > .watch-done div span {
    color: #57aa32;
    font-size: 12px;
    margin: 0 20px;
}

.accordion-course .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4f5d75;
    background-color: #eeeaeb;
    padding: 10px 20px;
}

.accordion-course .card-body p {
    margin: 0;
    font-size: 15px;
    cursor: pointer;
}

.accordion-course .card-body span {
    font-size: 12px;
}

.accordion-course .disabled-session p,
.accordion-course .disabled-session span {
    color: #9fa4b0;
}

.planOfCourse .queries-circle {
    top: -30px;
    height: 110%;
}

.planOfCourse .queries-circle img {
    padding: 0;
}

.courseVideo {
    width: 100% !important;
}

.courseVideo iframe {
    border-radius: 10px;
    border: 6px solid #ef8354cf;
}

/* end course details section  */

/* register course section  */

.register-col-know-more-buttom {
    text-align: left;
    z-index: 88;
}

.register-col-know-more-buttom .know-more-button {
    padding: 5px 40px;
}

.RegisterCourse-socialMedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.RegisterCourse-socialMedia .share-training {
    width: 32%;
    text-decoration: none;
    text-align: right;
}

.RegisterCourse-socialMedia a {
    color: #ef8354;
    font-size: 20px;
}

.RegisterCourse-socialMedia a:hover {
    text-decoration: none;
    color: #4f5d75;
}

.RegisterCourse-socialMedia-socialMedia {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 68%;
}

.RegisterCourse-socialMedia-socialMedia a i {
    font-size: 30px;
}

.whatLearn-forWho .know-more-button {
    padding: 10px 30px;
    margin-top: 0;
    width: 100%;
    font-size: 20px;
}

.register-course-plan-course {
    background-color: white;
    box-shadow: 0px 0px 12px 0px silver;
    border-radius: 10px;
    margin-bottom: 20px;
}

.register-course-plan-course-title {
    text-align: right;
    margin-bottom: 25px;
}

.register-course-plan-course-title span {
    font-size: 20px;
    margin: 0 20px;
    color: #4f5d75;
}

.register-course-plan-course ul {
    list-style: none;
    padding: 0;
}

.register-course-plan-course li {
    cursor: pointer;
    padding: 0 5px 0 25px;
}

.register-course-plan-course .active {
    background-color: #ebedf0;
}

.register-course-plan-course ul .register-course-plan-dropdown-title {
    border-bottom: 2px solid #cbcfd6;
    padding-left: 5px;
}

.register-course-plan-course ul .dropLeft-frstchild {
    border-top: 2px solid #cbcfd6;
}

.register-course-plan-course ul .register-course-plan-dropdown-title div {
    color: #9da5b3;
}

.register-course-plan-dropdown-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.register-course-plan-dropdown-title div {
    display: flex;
    align-items: center;
}

.register-course-plan-dropdown-title img {
    width: 10px;
}

.register-course-plan-dropdown-title span {
    padding: 0 20px;
}

.register-course-plan-course-right {
    padding: 40px 65px 40px 0px;
}

.register-course-plan-course-left {
    margin-left: 15px;
    height: 100%;
    background-color: #ebedf0;
    border-radius: 10px;
    padding: 35px;
}

.register-course-plan-course-left .item {
    color: #a6adba;
    border-bottom: 3px solid #cbcfd6;
    display: flex;
    justify-content: space-between;
    padding: 5px 25px;
}

/* end register course section  */

/* why choose use section  */

.box-shadow-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 12%);
}

.ourClients-workExperience {
    margin-bottom: 122px;
}
.whyChooseUs-carousel-title {
    position: absolute;
    top: -6%;
    margin: auto;
    left: 5%;
    color: white;
    padding: 19%;
    font-size: 20px;
}

.CarouselWhyChooseUs .carousel-caption p,
.WhyChooseUs .carousel-caption p {
    font-size: 28px;
    margin-bottom: 5px;
}

.WhyChooseUs .carousel-item img,
.CarouselWhyChooseUs .carousel-item img {
    margin-bottom: 6%;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    margin-bottom: 15px;
}

.ourClients-workExperience .carousel-indicators,
.CarouselWhyChooseUs .carousel-indicators,
.WhyChooseUs .carousel-indicators {
    margin: 0;
    padding: 0;
}

.ourClients-workExperience .carousel-indicators {
    bottom: -55px;
}

.ourClients-workExperience .carousel-indicators li,
.WhyChooseUs .carousel-indicators li,
.CarouselWhyChooseUs .carousel-indicators li {
    padding: 5px;
    border-radius: 50%;
    width: auto;
    height: auto;
}

.ourClients-workExperience .carousel-indicators li {
    background-color: #ef8354;
}

.ourClients-workExperience .carousel-indicators .active,
.WhyChooseUs .carousel-indicators .active,
.CarouselWhyChooseUs .carousel-indicators .active {
    transform: scale(1.3);
}

.WhyChooseUs .queries {
    background-color: white;
}

.manager-company {
    padding: 33px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 8px 1px silver;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
}

.manager-company img {
    width: 50px;
    height: 50px;
}

.manager p {
    color: #4f5d75;
}

.ourClients {
    background: url("/img/Group865.png");
    height: 354px;
}

.ourClients .ourServices-title {
    margin-top: 50px;
}

.ourClients .ourServices-title h3 {
    color: white;
}

.ourClients-carousle-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ourClients-workExperience img,
.ourClients-carousle-item img {
    width: 120px;
}

/* end  why choose use section  */

/* packages section  */

.Pachages .personal-training {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.packages-personal-training-item {
    display: flex;
    align-items: end;
}

.Pachages .personal-training .queries {
    background-color: white;
}

.packages-personal-training-item .queries-info {
    padding: 15px;
}

.packages-personal-training-item .queries-info img {
    margin-bottom: 0;
}

.packages-personal-training-item-discription {
    margin-top: 30px;
    text-align: right;
    margin-right: 25px;
}

.packages-personal-training-item-discription h6 {
    color: #ef8354;
    font-size: 20px;
}

.packages-personal-training-item-discription p {
    color: #4f5d75;
}

.Pachages .queries {
    z-index: -11;
}

.Pachages .half-circle {
    top: -25%;
    z-index: -1;
    width: 15%;
}

.packages-type .ourServices-title h3::after {
    bottom: 0px;
}

.packages-type-item-card {
    background-color: white;
    box-shadow: 0 0 5px 2px silver;
    border-radius: 15px;
    margin: 0 10px;
}

.packages-type-item-card h5 {
    padding: 25px;
    background-color: #ef8354;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    font-size: 26px;
}

.packages-type-item-card div {
    font-size: 25px;
    color: #ef8354;
}

.div-timeOf_course {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.timeOf_course {
    color: #4f5d75;
}

.package-price span:first-child {
    font-size: 45px;
    color: #ef8354;
    margin-right: 10px;
}

.packages-type-item-card div span {
    font-size: 25px;
}

.packages-type-item-card .know-more-button {
    padding: 11px 60px;
    background-color: #4f5d75;
    color: white;
    border-radius: 5px;
    margin-top: 0px;
    box-shadow: 0px 0px 5px 0px #4f5d75;
    margin-bottom: -19px;
}

.packages-type-item-card .know-more-button:hover {
    background-color: #ef8354;
}

.packages-type-item-card ul {
    list-style: none;
    padding: 0;
}

.packages-type-item-card ul li:nth-child(odd) {
    background-color: #e2e5e9;
}

.packages-type-item-card ul li {
    display: flex;
    justify-content: space-between;
    color: #4f5d75;
    padding: 10px 20px;
    align-items: baseline;
}

.packages-type-item-card ul li .timeOf_course {
    font-size: 25px;
}

.packages-type-row {
    align-items: flex-end;
    margin-top: 40px;
}

/* end packages section  */

/* publiction section  */

.publications-item {
    margin-top: 30px;
    padding: 20px 40px;
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 8px 1px silver;
    border-radius: 5px;
}

.titleOfPublication {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ef8354;
    color: #4f5d75;
    padding-bottom: 10px;
}

.titleOfPublication h6,
.titleOfPublication p {
    margin: 0;
}

.titleOfPublication p {
    display: flex;
    /* align-items: baseline; */
}

.titleOfPublication p img {
    width: 25px;
    height: 25px;
}

.publications-item ul {
    list-style: none;
    padding: 0;
}

.publications-item ul li a {
    border-bottom: 2px solid #4f5d75;
    color: #a7aeba;
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.publications-item ul li a span:first-child {
    width: 85%;
    color: #a7aeba;
}

.publications-item ul li a span:last-child {
    color: #4f5d75;
    width: 15%;
    text-align: left;
    font-size: 18px;
}

.titleOfPublication img {
    margin-right: 10px;
    cursor: pointer;
}

.Publications .ourServices-title {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 40px;
}

.Publications .ourServices-title h3::after {
    bottom: 11px;
}

.publications-section .header-point-img {
    width: 17%;
    bottom: -115px;
}

.filter {
    position: relative;
}

.filter .react-datepicker-wrapper {
    position: absolute;
    left: 0px;
    width: 25px;
    height: 25px;
    opacity: 0;
}

.filter .react-datepicker__input-container {
    height: 25px;
}

.filter .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    display: none;
}

.filter .react-datepicker [type="button"]:not(:disabled),
.filter .react-datepicker [type="reset"]:not(:disabled),
.filter .react-datepicker [type="submit"]:not(:disabled),
.filter .react-datepicker button:not(:disabled) {
    display: none;
}

/* end publiction section  */

/* work experience section  */

.WorkExperience .queries {
    background-color: transparent;
}

.workExperience-discription {
    text-align: right;
    margin-top: 45px;
}

.workExperience-discription h4 {
    color: #ef8354;
    margin-bottom: 20px;
    font-size: 35px;
}

.workExperience-discription-col {
    margin: auto;
}

.workExperience-discription p {
    color: #4f5d75;
}

.workExperience-modal-video {
    margin: auto;
}

.WorkExperience .more-details {
    text-align: right;
    margin-bottom: 40px;
}

.WorkExperience .more-details p {
    color: #4f5d75;
}

.numOfTrainees {
    background-color: #eef2f7;
    padding: 40px;
}

.numOfTrainees-item {
    padding: 50px;
    text-align: center;
}

.numOfTrainees-item img {
    width: 55px;
}

.numOfTrainees-item p {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #4f5d75;
}

.numOfTrainees-item span {
    font-size: 30px;
    color: #4f5d75;
    border-bottom: 1px solid #ef8354;
    border-top: 1px solid #ef8354;
    width: 100%;
    display: block;
}

.goodnessOfWork {
    margin: 50px 0;
}

.goodnessOfWork-discription {
    text-align: right;
    padding: 40px 0;
    color: #4f5d75;
}

/* end  work experience section  */

/* books page section  */

.books-title {
    text-align: right;
    margin-bottom: 20px;
}

.books-title h3 {
    color: #4f5d75;
    -webkit-text-decoration-color: #ef8354;
    text-decoration-color: #ef8354;
    font-size: 30px;
    border-bottom: solid #ef8354;
    border-width: 2px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: -webkit-fill-available;
    line-height: 1.2;
}

.books-title h3::after {
    height: 2px;
    width: 30px;
    background-color: #ef8354;
    content: "";
    position: absolute;
    top: 15px;
    margin: 0px 10px;
    display: none;
}

.Uploadbook {
    background: #ef8354;
    padding: 3px 12px;
    color: #2d3142;
    text-decoration: none;
    border-radius: 3px;
}
 
.Uploadbook:hover {
    background: #2d3142; 
    color: #ef8354 ; 
    text-decoration: none;
    transition: 0.5s;
}

.books-discription {
    padding-right: 60px;
}

.BookPage .ourServices-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.BookPage .ourServices-title h3 {
    margin: 0 15px;
    font-size: 30px;
}

.BookPage .ourServices-title h3:after {
    bottom: 11px;
}

.BookPage .ourServices-paragraph .showMoreParagraph {
    margin-top: 20px;
    color: #ef8354;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

.BookPage .ourServices-paragraph p:hover {
    color: #4f5d75;
    transition: ease-in-out 0.3s;
}

.BookPage .queries-circle {
    height: 100%;
    z-index: -1;
}

.BookPage .carousel-control-prev-icon {
    content: url("/img/Icon feather-arrow-down-left.png");
    position: absolute;
    left: 100%;
    bottom: auto;
    cursor: pointer;
    background-image: none !important;
    height: 30px;
}

.BookPage .carousel-control-next-icon {
    content: url("/img/Iconfeather-arrow-down.png");
    position: absolute;
    right: 100%;
    left: auto;
    bottom: auto;
    cursor: pointer;
    background-image: none !important;
    height: 30px;
}

.BookPage .carousel-control-next,
.BookPage .carousel-control-prev {
    top: auto;
    bottom: 25%;
}

.BookPage .carousel-indicators {
    width: 10px;
    right: -90px;
    bottom: 35%;
    left: auto;
    flex-direction: column;
}

.BookPage .carousel-indicators li {
    padding: 4px;
    border-radius: 50%;
    width: auto;
    height: auto;
    background-color: #ef8354;
    margin-bottom: -10px;
}

.BookPage .carousel-indicators .active {
    transform: scale(1.3);
}

.BookPage .books .books-carousel {
    margin-top: 0;
    display: inline-block;
}

.BookPage .books .books-carousel img {
    width: 80%;
    cursor: pointer;
    height: -webkit-fill-available;
}

.BookPage .books-discription {
    width: 75%;
    margin: auto;
}

.bookPage-circle-img {
    width: auto;
    position: absolute;
    left: auto;
    top: auto;
    z-index: -1;
    bottom: -10px;
    right: 0;
    height: 70%;
}

.carousel {
    direction: ltr;
}

.tearms-title {
    margin-bottom: 50px;
    margin-top: 50px;
}

.swal-button--danger {
    background-color: #ef8354;
}

.swal-footer {
    text-align: left;
}

/* Articles page section  */

.Article {
    margin-top: 40px;
}

.article-circle {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
}

.article-circle img {
    height: 100%;
    padding: 25px 0;
}

.Article .article-img {
    position: relative;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
}

.Article .article-img img {
    cursor: pointer;
}

.Article .article-img img:first-child {
    max-height: 330px;
    object-fit: fill;
    border-radius: 15px;
}

.article-title {
    color: #ef8354;
    text-align: right;
    margin-bottom: 40px;
    font-size: 25px;
}

.article-paragraph {
    color: #4f5d75;
    text-align: right;
    /* width: 80%; */
    margin-left: auto;
}

.random-article-right .article-paragraph {
    margin-bottom: 40px;
}

.article-paragraph span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.Article .carousel-indicators li {
    padding: 4px;
    border-radius: 50%;
    width: auto;
    height: auto;
    background-color: #ef8354;
    margin-bottom: -10px;
}

.Article .carousel-indicators .active {
    transform: scale(1.5);
}

.Article .carousel-control-next {
    left: 10%;
    z-index: 99;
}

.Article .carousel-control-prev {
    left: 0%;
}

.Article .carousel-control-next span {
    background-image: url("/img/Icon feather-arrow-down.png") !important;
    background-size: contain;
}

.Article .carousel-control-prev span {
    background-image: url("/img/Icon feather-arrow-down-left.png") !important;
    background-size: contain;
}

/* .Article .carousel-control-prev{

} */

.Article .carousel-control-next,
.Article .carousel-control-prev {
    bottom: 0 !important;
    background-color: #f6d8cd;
    top: auto !important;
    width: 40px !important;
    height: 40px !important;
}

.timeToRead {
    text-align: right;
    margin-top: 40px;
    color: #bfc0c0;
}

.timeToRead span {
    font-family: "Regular";
}

.timeToRead span:first-child {
    margin-right: 10px;
}

.Article .carousel {
    z-index: 8;
}

.Article .carousel-item {
    padding-bottom: 50px;
}

.Article .carousel-item a {
    text-decoration: none;
}

.margin-auto {
    margin: auto;
}

.random-article {
    margin: 60px 0;
    background-color: #eff3f8;
    padding: 40px 0;
}

.random-article-sec2 {
    background-color: #fff;
    margin: 0;
}

.random-article-right,
.random-article-left {
    text-align: right;
}

.random-article-left .mainArticalImg {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 15px;
    height: 370px;
    object-fit: fill;
}

.random-article-right h2,
.random-article-left h2 {
    color: #ef8354;
    font-size: 20px;
}

.random-article-right h2 {
    margin-bottom: 30px;
}

.timeToRead-ramdom-article {
    color: #bfc0c0;
}

.timeToRead-ramdom-article span:first-child {
    margin-left: 10px;
}

.likeAndTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.random-article-left .likeAndTime {
    margin-top: 5px;
}

.numImgLike span {
    color: #2d3142;
}

.likeAndTime img {
    cursor: pointer;
    width: 35px;
    object-fit: scale-down;
}

.random-article-right {
    display: flex;
    /* align-items: center; */
    margin-bottom: 45px;
    margin-right: 40px;
}

.random-article-right-sec2 {
    margin-right: 0;
    margin-left: 40px;
}

.random-article-right .mainArticalImg {
    width: 50%;
    object-fit: fill;
    margin-bottom: auto;
    border-radius: 15px;
    height: 270px;
    cursor: pointer;
}

.random-article-detalis {
    margin-right: 25px;
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 10px;
}

.random-article-right,
.random-article-left a,
.random-article-detalis a {
    text-decoration: none !important;
}

/* Articles page section end */

/* Article details page section  */

.ArticleDetails {
    margin-top: 50px;
}

.article-info {
    padding: 30px;
    background-color: #eef2f7;
    border-radius: 15px;
}

.article-info .mainArticleImg {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 15px;
    height: 425px;
    object-fit: fill;
}

.article-info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 2px solid #ef835470;
}

.article-info-header h2 {
    color: #ef8354;
    text-align: right;
    font-size: 25px;
}

.timeToRead-article-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #bfc0c0;
}

.likeAndShear {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.numImgLike {
    margin-right: 5px;
}

.likeAndShear .numImgLike {
    border-radius: 10px;
}

.likeAndShear img {
    cursor: pointer;
    width: 30px;
}

.shearImg {
    width: 60px;
}

.likeAndShear .shareDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    background-color: #ef8354;
    border-radius: 10px;
    width: 60px;
    height: 53px;
    padding-bottom: 0;
    cursor: pointer;
}

/* .likeAndShear img:last-child{
  margin-right: 20px;
} */

.article-info-body p {
    color: #4f5d75;
    padding-top: 20px;
    text-align: right;
}

.read-more {
    text-align: right;
}

.read-more h3 {
    padding: 20px;
    background-color: #ef8354;
    color: white;
    /* border-radius: 15px; */
    /* padding-right: 20px; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.read-more ul {
    list-style: none;
    padding-right: 20px;
    margin: 0;
}

.read-more ul li {
    padding: 10px 0;
    border-bottom: 1px solid #ef8354;
}

.read-more ul li h4 {
    font-size: 20px;
    color: #2d3142;
}

.read-more ul li a {
    color: #4f5d75;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.timeToRead-article-info-body {
    display: flex;
    align-items: flex-end;
    color: #bfc0c0;
    justify-content: space-between;
    margin-top: 5px;
}

.shareModal .modal-header .close {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.shareModal .ShareRow ul {
    display: flex;
    list-style: none;
    text-align: right;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.shareModal .ShareRow ul a li {
    cursor: pointer;
}

.shareModal .ShareRow ul a li button:focus {
    outline: none !important;
}

.numImgLike {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    background-color: #ef8354;
    border-radius: 10px;
    width: 60px;
    padding-bottom: 0;
    cursor: pointer;
}

.numImgLikeCourasel {
    padding: 15px;
    border: 2px solid #ef8354;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: dashed;
    position: absolute;
    bottom: -30px;
    /* left: 150px; */
    left: -45px;
    /* width: 125px; */
    cursor: pointer;
}

.numImgLikeCourasel div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #ef8354;
    padding-bottom: 0;
    border-radius: 50%;
    width: 85px;
    cursor: pointer;
}

.numImgLikeCourasel div img {
    width: auto !important;
}

.numImgLikeCourasel div span {
    color: #2d3142;
}

.modal-title {
    color: #2d3142 !important;
}

/* .Article .article-img img:last-child{

 
} */

/* Article details page section end  */

/* @media  query*/

@media only screen and (min-width: 1531px) {
    .contactUs-socialMedia {
        width: 55%;
    }
    .dateLikeShaer {
        width: 30%;
    }
}

@media only screen and (max-width: 1530px) {
    .books .css-1fzpoyk {
        top: 20% !important;
        transform-origin: 300% 60% !important;
        overflow: hidden;
    }
    .books .css-1qzevvg {
        width: 110% !important;
        bottom: 75%;
        z-index: 9999;
    }
    .landing .scroll-icon {
        bottom: 0;
        right: 50px;
    }
    .training-landing-card {
        padding: 25px;
    }
    .training-landing-card img {
        width: 38px;
        height: 38px;
    }
    .training-landing-card span {
        font-size: 16px;
    }
    .structure-training .personal-training .queries-info {
        padding: 40px 15px;
    }
    .Training .commingSoon-label span {
        font-size: 18px;
    }
    .CarouselWhyChooseUs .carousel-caption p,
    .WhyChooseUs .carousel-caption p {
        font-size: 23px;
        margin-bottom: 5px;
    }
    .WhyChooseUs .carousel-caption,
    .CarouselWhyChooseUs .carousel-caption {
        padding: 0;
    }
    .packages-type-item-card {
        margin: 0;
    }
    .packages-type-item-card .know-more-button {
        padding: 11px 50px;
    }
    .BookPage .books .books-carousel {
        width: 100%;
        margin-top: 70px;
    }
    .BookPage .carousel-indicators {
        width: 10px;
        right: -100px;
        left: auto;
        flex-direction: column;
        bottom: 25%;
    }
    .books-arrow-left {
        left: 15%;
    }
    .books-arrow-right {
        right: 15%;
    }
    .article-info-header h2 {
        width: 75%;
        font-size: 22px;
    }
    .article-info-header .dateLikeShaer {
        width: 25%;
    }
    .timeToRead-article-info {
        font-size: 14px;
    }
}

@media (min-width: 1200px) and (max-width: 1530px) {
    .structure-training .personal-training .queries-info div {
        margin-right: 0;
    }
    .structure-training .personal-training .queries-info {
        height: 85%;
    }
    .structure-training .personal-training .queries-info .img-lamp {
        width: 100px;
    }
    .structure-training .padding-3px {
        padding: 3px !important;
    }
    .courseDetails-page .queries .padding-5px {
        padding: 5px !important;
    }
    .course-details-special p {
        margin: 0;
    }
    .course-details p {
        font-size: 20px;
    }
    .RegisterCourse .container-fluid {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
    .courseDetails-page .queries .course-details,
    .Training-landing-page .course-details {
        height: 100%;
    }
    .whyChooseUs-carousel-title {
        font-size: 18px;
    }

    .person-bg-col { 
        background-position: left;
        background-size: contain; 
        padding: 0 !important; 
        transform: translate(2%, -4%);
        -ms-transform: translate(2%, -4%);
        -webkit-transform: translate(0%, -2%); 
    }

    .person-bg-col img { 
        transform: translate(2%, -2%);
        -ms-transform: translate(2%, -2%);
        -webkit-transform: translate(1%, 1%); 
        height: 92%;
    }
}

@media only screen and (min-width: 1200px) {
    .accordion {
        width: 80%;
    } 
}

@media only screen and (max-width: 1200px) {
    /* landing section */
    .social-header {
        right: 10px;
        bottom: -120px;
    }
    /* end landing section */
    .half-circle {
        bottom: -45px;
    }
    .queries-points-img {
        left: 20%;
    }
    .queries-points-img img {
        width: 75%;
    }
    .books .css-1qzevvg {
        bottom: 75%;
        width: 115% !important;
    }
    .training-img p {
        padding-bottom: 15px;
        font-size: 20px;
    }
    .books .css-1fzpoyk {
        left: 72% !important;
    }
    .books .css-1qzevvg {
        bottom: 75%;
        width: 155% !important;
    }
    .Home .Training .queries-circle {
        top: -200px;
    }
    .whyChooseUs-carousel-title {
        font-size: 15px;
    }
    .WhyChooseUs .carousel-caption p,
    .CarouselWhyChooseUs .carousel-caption p {
        font-size: 21px;
    }
    .ourClients-workExperience img,
    .ourClients-carousle-item img {
        width: 100px;
    }
    .ourClients {
        height: 300px;
    }
    .whyChooseUs-contact .contactUs-form {
        padding: 20px 20px;
    }
    .contact-img-whyChooseUs img {
        width: 100%;
    }
    .contact-img-whyChooseUs {
        display: none;
    }
    .Pachages .half-circle {
        top: -5%;
    }
    .numOfTrainees-item {
        padding: 30px;
    }
    .BookPage .carousel-indicators {
        width: 10px;
        right: -35px;
        bottom: 35%;
    }
    .BookPage .books-discription {
        width: 100%;
        padding-right: 0;
    }

    .MeasureText p { 
        font-size: 22px;
        margin-bottom: 0;
    }

    .training-img .training-img-img {
        height: 300px;
    }
    .article-paragraph {
        width: 100%;
    }
    .Article .article-img img:first-child {
        width: 100%;
    }
    .Article .carousel-control-next {
        left: 22%;
    }
    .article-title,
    .random-article-right h2,
    .random-article-left h2 {
        font-size: 18px;
    }
    .random-article-right-sec2 {
        margin-left: 25px;
    }
    .random-article-right {
        margin-bottom: 30px;
        margin-right: 25px;
    }
    .timeToRead-ramdom-article {
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }
    /* .likeAndShear img{
  width: 50px;
} */
    .likeAndShear .numImgLike img {
        width: 30px;
        object-fit: scale-down;
    }
    .shearImg {
        width: 55px;
    }
    .article-info-header h2 {
        font-size: 20px;
        width: 70%;
    }
    .timeToRead-article-info {
        font-size: 14px;
    }
    .article-info-header .dateLikeShaer {
        width: 30%;
    }
    .read-more h3 {
        font-size: 20px;
    }
    .read-more ul li h4 {
        font-size: 18px;
    }
    .timeToRead-article-info-body {
        font-size: 14px;
    }
    .numImgLike {
        width: 60px;
    }
    .likeAndTime img {
        width: 30px;
        object-fit: scale-down;
    }
    .landing-details h2 {
        font-size: 60px;
    } 

    
}

@media (min-width: 992px) and (max-width: 1200px) {
    .footer-Visitors {
        flex-direction: column;
    }
    .footer .numOf-Visitors {
        margin: 0;
    }
    .Training-page .padding-5px {
        padding: 5px !important;
    }
    .courseDetails-page .container-fluid {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
    .package-price span:first-child {
        font-size: 30px;
        margin-right: 10px;
    }
    .packages-type-item-card div span {
        font-size: 20px;
    }
    .packages-type-item-card h5 {
        font-size: 20px;
    }
    .packages-type-item-card ul li .timeOf_course {
        font-size: 20px;
    }
    .BookPage .container-fluid {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
    .books-arrow-right {
        right: -30px;
    }
    
    
    .person-bg-col {
        background: url(/img/Ellipse52.png) no-repeat;
        background-position: right;
        background-size: cover;
        display: flex;
        padding: 16px !important;
        z-index: 999999999;
        flex-wrap: wrap;
        transform: translate(2%, -4%);
        -ms-transform: translate(2%, -4%);
        -webkit-transform: translate(0%, 1%);
        z-index: -1;
    }

    .person-bg-col img {
        width: 100%;
        /* object-fit: scale-down; */
        transform: translate(2%, -2%);
        -ms-transform: translate(2%, -2%);
        -webkit-transform: translate(0, -2%);
        width: 132%;
        height: 100%;
    }
}

@media only screen and (max-width: 992px) {
    /*  header section  */
    .navbar-nav {
        width: 150px;
        background-color: #fff;
        text-align: right;
        position: absolute;
        left: 0;
        z-index: 99999;
    }
    .header-logo {
        height: 190px;
        width: 140px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0 !important;
    }
    /* end header section  */
    /* landing section */
    /* .person-bg img {
    width: 400px;
  } */
    .landing-details {
        margin-top: 120px;
    }
    .landing-details p,
    .landing-details h6 {
        color: #4f5d75;
        font-size: 14px;
    }
    .landing-details h2 {
        font-size: 40px;
        margin: 10px 0;
    }
    .landing .scroll-icon {
        width: 40px;
        height: 40px;
        bottom: -55px;
        right: 40px;
    }
    .social-header {
        right: 10px;
        bottom: -60px;
    }
    .social-header div div {
        height: 50px;
    }
    /* end landing section */
    .ourService-tab-section {
        width: 100%;
    }
    .tab-icon img {
        width: 40px;
        height: 40px;
    }
    .navbar-web {
        display: none;
    }
    .mobile-push-menu {
        display: block;
    }
    .styles_scroll-to-top__2A70v,
    .landing .scroll-icon {
        display: none;
    }
    .half-circle {
        bottom: -10px;
    }
    .queries-circle {
        width: 31%;
    }
    .queries-points-img img {
        width: 55%;
    }
    .queries-points-img {
        top: 100px;
        left: 17%;
    }
    .queries-video {
        margin-top: 30px;
    }
    .queries-info img {
        width: 35px;
    }
    .queries .know-more-button {
        margin: auto;
        margin-top: 60px;
    }
    .training-img p {
        padding-bottom: 15px;
        font-size: 20px;
    }
    .books .css-1fzpoyk {
        left: 50% !important;
    }
    .books .css-1qzevvg {
        width: 100% !important;
    }
    .training-landing-card {
        padding: 14px;
        margin-top: -35px;
    }
    .training-landing-card img {
        width: 25px;
        height: 25px;
        margin: 0 5px;
    }
    .training-landing-card span {
        font-size: 13px;
    }
    .training-landing-title {
        width: 60%;
    }
    .structure-training .personal-training .queries-info {
        height: 92%;
    }
    .register-course-plan-course-right {
        padding: 40px 30px 40px 0px;
    }
    .register-course-plan-dropdown-title span {
        padding: 0 10px;
        font-size: 14px;
    }
    .register-course-plan-course-left .item {
        padding: 5px 5px;
    }
    .register-course-plan-course-left .item span {
        font-size: 12px;
    }
    .Training .commingSoon-label span {
        font-size: 15px;
    }
    .register-course-plan-course-title img {
        width: 41px;
        height: 41px;
        object-fit: scale-down;
    }
    .Training .queries-circle img {
        width: -webkit-fill-available;
        object-fit: contain;
    }
    .whyChooseUs-carousel-title {
        font-size: 14px;
        top: 0%;
        padding: 15%;
    }
    .WhyChooseUs .carousel-caption p,
    .CarouselWhyChooseUs .carousel-caption p {
        font-size: 19px;
    }
    .ourClients {
        height: 265px;
    }
    .ourClients .ourServices-title {
        margin-top: 35px;
    }
    .publications-section .header-point-img {
        width: 20%;
        bottom: -50px;
    }
    .books-discription {
        margin-bottom: 65px;
    }
    .books .books-carousel {
        margin: 0 auto;
    }
    .BookPage .books .books-carousel {
        margin-bottom: 100px;
        margin-top: 0;
    }
    .thumb-employee-img {
        width: 80px;
        height: 80px;
    }
    .timeToRead span {
        font-size: 14px;
    }
    .random-article-left {
        margin-bottom: 30px;
    }
    .random-article-left-sec2 {
        margin-bottom: 0;
        margin-top: 30px;
    }
    .random-article-right-sec2 {
        margin-left: 0;
    }
    .random-article-right {
        margin-right: 0;
    }
    .article-info-header {
        flex-direction: column;
    }
    .article-info-header h2 {
        width: 100%;
        font-size: 18px;
    }
    .article-info-header .dateLikeShaer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .timeToRead-article-info {
        flex-direction: row;
    }
    .timeToRead-article-info span:last-child {
        margin-right: 5px;
    }
    .likeAndShear {
        margin-top: 0;
    }
    .likeAndShear .numImgLike img {
        width: 25px;
    }
    .likeAndShear .numImgLike span {
        font-size: 11px;
    }
    .likeAndShear img {
        width: 35px;
    }
    /* .likeAndShear img:last-child {
  margin-right: 12px;
} */
    .timeToRead-article-info {
        font-size: 12px;
    }
    .read-more h3 {
        padding: 10px;
        font-size: 18px;
    }
    .read-more ul li a {
        font-size: 14px;
    }
    .timeToRead-article-info-body {
        font-size: 13px;
    }
    .article-info {
        padding: 20px;
    }
    .numImgLike {
        margin-right: 3px;
        width: 40px;
    }
    .likeAndShear .shareDiv {
        width: 40px;
        height: 41px;
    }
    .likeAndShear img {
        width: 25px;
    }
    .timeToRead-article-info-body {
        flex-direction: column;
        align-items: flex-start;
    }
    .ourService-hover h4 {
        font-size: 20px;
    }
    .Training .commingSoon-label div {
        width: 60px;
        height: 60px;
    }

    .Training {
        margin: 2px 0 238px 0;
    }

    
}

@media (min-width: 700px) and (max-width: 1000px) {
    .ArticleDetails .container-fluid {
        padding-right: 66px !important;
        padding-left: 66px !important;
    }
    /* .person-bg-col {
        background-position: left;
        background-size: contain;
        padding: 16px !important;
        transform: translate(2%, -4%);
        -ms-transform: translate(2%, -4%);
        -webkit-transform: translate(0%, -2%);
    }
    .person-bg-col img {
        transform: translate(1%, 78%);
        -ms-transform: translate(1%, 78%);
        -webkit-transform: translate(1%, 78%);
        height: auto;
    } */
    .person-bg-col {
        background: url(/img/Ellipse52.png) no-repeat;  
        transform: translate(2%, -4%);
        -ms-transform: translate(2%, -4%);
        -webkit-transform: translate(0%, 1%);
        z-index: -1;
    }

    .person-bg-col img {
        width: 100%;
        /* object-fit: scale-down; */
        transform: translate(14%, -3%);
        -ms-transform: translate(14%, -3%);
        -webkit-transform: translate(14%, -3%);
        width: 123%; 
        height: 100%;
    }

}

@media (min-width: 767px) and (max-width: 992px) {
    .direct-training .container-fluid {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
    .padding-3px-direct {
        padding: 3px !important;
    }
    .RegisterCourse-socialMedia a {
        font-size: 17px;
    }
    .RegisterCourse-socialMedia-socialMedia a i {
        font-size: 20px;
    }
    .course-details-special p {
        margin: 0px 0px;
    }
    .Home .course-details {
        height: 100%;
    }
    .direct-training .queries .course-details {
        height: 100%;
        margin-bottom: 0px;
    }
    .manager-company {
        padding: 25px;
        width: 100px;
    }
    .manager-company img {
        width: 40px;
        height: 40px;
    }
    .packages-type .container-fluid,
    .Pachages .personal-training .container-fluid {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
    .packages-type-item-card h5 {
        padding: 20px;
        font-size: 20px;
    }
    .packages-type-item-card ul li {
        padding: 5px 15px;
    }
    .packages-type-item-card ul li .timeOf_course {
        font-size: 20px;
    }
    .package-price span:first-child {
        font-size: 30px;
        color: #ef8354;
        margin-right: 10px;
    }
    .packages-type-item-card div span {
        font-size: 18px;
    }
    .packages-type-item-card .know-more-button {
        padding: 5px 30px;
    }
    .random-article-left .mainArticalImg {
        height: 300px;
    }
    .ExamImg {
        margin-top: 0;
        display: none;
    }
    .MeasureStart ul li .TestBox {
        padding: 3px;
        margin: 22px 3px -75px;
    }
    .MeasureStart ul li .TestBox h4 {
        font-size: 19px;
    }
    .ourServices-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content;
        line-height: 1.1; 
    }
    
}

@media only screen and (max-width: 767px) {
    .web-landing-bg-person {
        display: none;
    }
    .mobile-landing-bg-person {
        display: block;
    }
    .person-bg img:last-child {
        /* width: 300px; */
        margin: auto;
        margin-left: 33px;
    }
    .landing-details {
        margin-top: 50px;
        text-align: right;
        margin: 0 auto;
    }
    .know-more-button {
        display: block;
        margin: auto;
    }
    .social-header {
        right: 15px;
        bottom: -40px;
    }
    .landing .scroll-icon {
        bottom: -30px;
        right: 40px;
    }
    .person-bg {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-bg img {
        position: relative;
    }
    .person-bg img:last-child {
        position: absolute;
        left: auto;
    }
    .landing-row {
        flex-flow: column-reverse;
    }
    .container-fluid {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .landing .container-fluid,
    .container-fluid {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
    .landing .row {
        margin-left: -15px !important;
    }
    .person-bg-col {
        background-size: contain;
        margin-top: 50px;
        background-position: center;
        /* width: 65% !important; */
        margin: auto;
    }
    .MediaUL ul {
        right: 2%;
        padding: 7px;
        background-color: white;
        bottom: 19%;
        border-radius: 10px;
        box-shadow: 1px 1px 11px #00000082;
    }
    .MediaUL ul:after {
        display: none;
    }
    .half-circle {
        bottom: 0;
        width: 12%;
    }
    .queries-points-img img {
        width: 50%;
        display: none;
    }
    .structure-training .queries-points-img img {
        display: block;
    }
    .queries-points-img {
        left: 10%;
    }
    .queries-video img:last-child {
        width: -webkit-fill-available;
    }
    .books .css-1qzevvg {
        height: 20px !important;
        width: 55% !important;
        bottom: 80% !important;
        z-index: 9999;
    }
    .books .books-carousel {
        /* height: 180px; */
        height: 330px;
    }
    .training-img p {
        padding-bottom: 10px;
        font-size: 17px;
    }
    .contactUs-socialMedia ul {
        display: flex;
        flex-direction: row-reverse;
    }
    .training-landing-title {
        width: 70%;
    }
    .training-landing-title h2 {
        font-size: 22px;
    }
    .ourServices-title h3::after {
        display: none;
    }
    .RegisterCourse-socialMedia {
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    .register-course-plan-course-title img {
        width: 35px;
    }
    .queries-video {
        width: 80%;
        margin: auto;
        margin-top: 30px;
    }
    .planOfCourse .queries-video {
        width: 100%;
    }
    .scroll-icon,
    .landing .scroll-icon {
        display: none;
    }
    .whyChooseUs-carousel-title {
        font-size: 12px;
        top: 8%;
        padding: 11%;
    }
    .ourClients-workExperience .carousel-indicators li,
    .CarouselWhyChooseUs .carousel-indicators li,
    .WhyChooseUs .carousel-indicators li {
        padding: 3px;
    }
    .WhyChooseUs .carousel-caption,
    .CarouselWhyChooseUs .carousel-caption {
        bottom: 14px;
    }
    .WhyChooseUs .carousel-caption p,
    .CarouselWhyChooseUs .carousel-caption p {
        font-size: 15px;
    }
    .WhyChooseUs .carousel-control-next-icon::after,
    .WhyChooseUs .carousel-control-prev-icon::after,
    .CarouselWhyChooseUs .carousel-control-next-icon::after,
    .CarouselWhyChooseUs .carousel-control-prev-icon::after {
        font-size: 30px;
    }
    .WhyChooseUs .carousel-control-prev-icon,
    .WhyChooseUs .carousel-control-next-icon,
    .CarouselWhyChooseUs .carousel-control-prev-icon,
    .CarouselWhyChooseUs .carousel-control-next-icon {
        height: 55px !important;
    }
    .contactUs-title h3::after {
        display: none;
    }
    .Pachages .half-circle {
        top: -5%;
        width: 25%;
    }
    .packages-type-item-card {
        margin-bottom: 40px;
        padding-bottom: 10px;
    }
    .BookPage .queries-circle {
        display: none;
    }
    .bookPage-circle-img {
        height: 55%;
        bottom: -150px;
    }
    .Publications .ourServices-title {
        margin-top: 85px;
    }
    .BookPage .ourServices-title {
        margin-top: 50px;
    }
    .react-multi-carousel-item {
        padding: 0 10px !important;
    }
    /* .contactUs .contactUs-form {
margin-top: 85px;
  } */
    .training-img .training-img-img {
        height: 250px;
    }
    .read-more {
        margin-top: 30px;
    }
    .article-info {
        padding: 20px;
    }
    .ArticleDetails {
        margin-top: 100px;
    }
    .numImgLikeCourasel div img {
        width: 30px !important;
    }
    .numImgLikeCourasel div {
        font-size: 14px;
        width: 63px;
    }
    .numImgLikeCourasel {
        padding: 12px;
        width: 85px;
    }
    .numImgLike {
        width: 40px;
    }
    .numImgLike span {
        font-size: 12px;
    }
    .likeAndTime img {
        width: 25px;
    }
    .Article .carousel {
        margin-top: 100px;
    }
    .article-title,
    .random-article-right h2,
    .random-article-left h2 {
        font-size: 16px;
    }
    .read-more ul li h4 {
        font-size: 16px;
    }
    .MediaUL ul i {
        font-size: 20px;
    }
    .ExamImg {
        margin-top: 0;
        display: none;
    }
    .MeasureStart ul li .TestBox {
        padding: 2px;
        margin: 13px 2px -75px;
        font-size: 12px;
    }
    .MeasureStart ul li .TestBox h4 {
        font-size: 14px;
    }
    .EmailSubs .buttonSub {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ReverseColsInMobile {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap-reverse;
    }

    .slick-slider {
        margin-bottom: 103px;
    }

    .ourServices-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content;
        line-height: 1.1; 
    }

}

@media only screen and (max-width: 576px) {
    .ourService-tab-section {
        margin-bottom: 20px;
    }
    .coverflow__container__1P-xE {
        height: 203px !important;
    }

    .buttonSub { 
        font-size: 19px; 
        width: 156px; 
        margin: 5px;
    }

    .books-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content; 
    }

    .ExamImg {
        margin-top: 0;
        display: none;
    }
    .header-logo {
        height: 125px;
        width: 140px;
    }
    .books .books-carousel {
        height: 250px;
    }
    .books .css-1qzevvg {
        width: 70% !important;
    }
    .training-img p {
        padding-bottom: 5px;
        font-size: 13px;
    }
    .recommendations .Employee-info {
        padding: 20px 20px;
    }
    /* .Training .iamfxf {

  width: 120px !important;
  } */
    /* .training-landing-card {
flex-direction: column;
  } */
    .padding-3px {
        padding: 3px !important;
    }
    .Training-landing-page {
        height: 570px;
    }
    .Training-landing-page-img {
        height: 550px;
    }
    .next-course-paragraph h2 {
        font-size: 28px;
    }
    .ikyGAt {
        font-size: 0.3em !important;
        /* width: 0px !important;
  height: 10px !important; */
    }
    .brcpxa {
        font-size: 0.3em !important;
    }
    .structure-training .personal-training .queries-info div {
        margin-right: 30%;
    }
    .structure-training .personal-training .queries-info {
        height: auto;
    }
    .register-course-plan-course li {
        margin-left: 15px;
    }
    .register-course-plan-course-left {
        margin-right: 15px;
        padding: 30px 10px;
    }
    .RegisterCourse-socialMedia-socialMedia a i {
        font-size: 22px;
    }
    .Training .queries-circle img {
        height: 100%;
        width: auto;
    }
    .whyChooseUs-carousel-title {
        font-size: 10px;
    }
    .ourClients-workExperience .carousel-indicators {
        bottom: -30px !important;
    }
    .carousel-indicators {
        bottom: -4px !important;
    }
    .CarouselWhyChooseUs .carousel-caption,
    .WhyChooseUs .carousel-caption {
        bottom: 9px;
    }
    .WhyChooseUs .carousel-caption p,
    .CarouselWhyChooseUs .carousel-caption p {
        font-size: 13px;
    }
    .Pachages .ourServices-title h3,
    .WhyChooseUs .ourServices-title h3 {
        font-size: 25px;
    }
    .ourClients-workExperience img,
    .ourClients-carousle-item img {
        width: 80px;
    }
    .ourClients {
        height: 220px;
    }
    .ourClients .ourServices-title {
        margin-top: 20px;
    }
    .manager-company {
        padding: 33px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .titleOfPublication p img {
        width: 20px;
        height: 20px;
    }
    .publications-item {
        margin-top: 30px;
        padding: 15px 25px;
    }
    .structure-training .personal-training .queries .know-more-button {
        width: 30%;
        padding: 10px;
    }
    .publications-section .header-point-img {
        width: 20%;
        bottom: 0px;
    }
    .goodnessOfWork-discription {
        text-align: right;
        padding: 25px 0;
        color: #4f5d75;
    }
    .BookPage .carousel-indicators {
        width: auto;
        right: 27%;
        left: auto;
        flex-direction: row;
        top: auto;
        bottom: -35px !important;
    }
    .Publications .ourServices-title {
        margin-top: 50px;
    }
    .BookPage .ourServices-title {
        margin-top: 20px;
    }
    .thumb-employee-img {
        width: 60px;
        height: 60px;
        margin-bottom: -15px !important;
    }
    .books-arrow-right,
    .books-arrow-left {
        height: 35px;
    }
    .training-img .training-img-img {
        height: 200px;
    }
    .Article .article-img {
        width: 80%;
        margin-bottom: 30px;
    }
    .timeToRead {
        margin: 20px 0;
    }
    /* .numImgLikeCourasel{
width: 80px;
  } */
    .Article .carousel-control-next {
        left: 25%;
    }
    .Article .carousel-control-prev {
        left: 0%;
    }
    .random-article-right h2 {
        margin-bottom: 7px;
    }
    .random-article-right .article-paragraph {
        margin-bottom: 20px;
    }
    .random-article-left .mainArticalImg {
        height: 250px;
    }
    .article-info .mainArticleImg {
        height: 350px;
    }
    .timeToRead-article-info-body {
        flex-direction: row;
    }
    .testimonials-arrow-right,
    .testimonials-arrow-left {
        width: 20px;
        top: calc(50% - 10px);
    }
    .coverflow__figure__3bk_C {
        max-height: 70px;
    }
    .MeasureStart ul li .TestBox {
        padding: 2px;
        margin: 13px 2px -75px;
        font-size: 12px;
    }
    .MeasureStart ul li .TestBox h4 {
        font-size: 14px;
    }

    .AllQuestions {
        padding: 5px;
    }

    .containerOuter .container {
        margin: 25px 7px 0;
    }
    .EmailSubs .buttonSub {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ReverseColsInMobile {
        display: flex !important;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap-reverse !important;
    }

    .ourServices-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content;
        line-height: 1.1; 
    }

}

@media only screen and (max-width: 425px) {
    /*  header section  */
    .header-logo,
    .header-point-img {
        width: 100px;
    }
    /* end header section  */
    .person-bg-col {
        margin-top: 0px !important;
    }
    .books .books-carousel {
        height: 200px;
    }
    .training-img p {
        padding-bottom: 0;
        font-size: 11px;
    }
    .recommendations .Employee-info {
        padding: 15px 10px;
    }
    .training-landing-title {
        width: 80%;
    }
    .training-landing-title h2 {
        font-size: 18px;
    }
    .Training .commingSoon-label span {
        font-size: 12px;
    }
    .Pachages .ourServices-title h3 img,
    .WhyChooseUs .ourServices-title img {
        width: 30px;
    }
    .personal-training .ourServices-title img {
        width: 40px;
    }
    .Pachages .ourServices-title h3,
    .WhyChooseUs .ourServices-title h3,
    .Training-page .ourServices-title h3 {
        font-size: 24px;
    }
    .contactUs .contactUs-form {
        padding: 20px 20px;
    }
    .structure-training .personal-training .queries-info div {
        margin-right: 25%;
    }
    .RegisterCourse-socialMedia-socialMedia {
        width: 60%;
    }
    .RegisterCourse-socialMedia .share-training {
        width: 40%;
    }
    .contactUs-form .checkmark {
        right: 0;
        margin: 0 9px;
        top: 3px;
    }
    .contactUs-form .container-input {
        margin-left: 10px;
        display: table-row;
        position: relative;
        /* padding-left: 35px; */
        /* margin-bottom: 12px; */
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    }
    .courseDetails-page .course-details-special .course-details-special-img img {
        width: 50px;
        height: 50px;
    }
    .course-details-special p {
        margin: 0px 0px;
    }
    .accordion-course .accordion > .card > .card-header {
        padding: 9px 10px;
    }
    .accordion-course .accordion > .card > .card-header div img {
        width: 11px;
    }
    .accordion-course .accordion > .card > .card-header div h4 {
        font-size: 13px;
        margin: 0 10px;
    }
    .accordion-course .accordion > .card > .watch-done div span {
        font-size: 11px;
        margin: 0 10px;
    }
    .whyChooseUs-carousel-title {
        font-size: 9px;
        top: 10%;
        padding: 11%;
        left: 0%;
    }
    .whyChooseUs-carousel-title {
        font-size: 9px;
    }
    .CarouselWhyChooseUs .carousel-caption,
    .WhyChooseUs .carousel-caption {
        bottom: 4px;
    }
    .CarouselWhyChooseUs .carousel-caption p,
    .WhyChooseUs .carousel-caption p {
        font-size: 11px;
    }
    .ourClients-workExperience .carousel-indicators li,
    .CarouselWhyChooseUs .carousel-indicators li,
    .WhyChooseUs .carousel-indicators li {
        padding: 2px;
    }
    .whyChooseUs-title-col {
        padding: 0 !important;
    }
    .ourClients-workExperience img,
    .ourClients-carousle-item img {
        width: 60px;
    }
    .ourClients .ourServices-title {
        margin-top: 10px;
    }
    .ourClients {
        height: 170px;
    }
    .CarouselWhyChooseUs .carousel-control-next-icon::after,
    .CarouselWhyChooseUs .carousel-control-prev-icon::after,
    .WhyChooseUs .carousel-control-next-icon::after,
    .WhyChooseUs .carousel-control-prev-icon::after {
        font-size: 20px;
    }
    .CarouselWhyChooseUs .carousel-control-prev-icon,
    .CarouselWhyChooseUs .carousel-control-next-icon,
    .WhyChooseUs .carousel-control-prev-icon,
    .WhyChooseUs .carousel-control-next-icon {
        height: 45px !important;
    }
    .packages-personal-training-item {
        display: block;
        margin-bottom: 30px;
        margin: 30px 0;
    }
    .packages-personal-training-item .queries-info {
        width: 50%;
        margin: auto;
    }
    .packages-personal-training-item-discription {
        margin-top: 20px;
        margin-right: 0;
        text-align: center;
    }
    .packages-type-item-card h5 {
        padding: 20px;
        margin: 0;
        font-size: 20px;
    }
    .packages-type-item-card div span {
        font-size: 18px;
    }
    .packages-type-item-card ul li .timeOf_course {
        font-size: 20px;
    }
    .package-price span:first-child {
        font-size: 30px;
        margin-right: 6px;
    }
    .packages-type-item-card .know-more-button {
        padding: 6px 35px;
    }
    .titleOfPublication h6,
    .titleOfPublication {
        font-size: 14px;
    }
    .publications-item ul li a span:first-child,
    .publications-item ul li a span:last-child {
        font-size: 12px;
    }
    .BookPage .books .books-carousel {
        margin-bottom: 50px;
    }
    .react-multiple-carousel__arrow--right {
        right: -10px !important;
    }
    .react-multiple-carousel__arrow--left {
        left: -10px !important;
    }
    /* .bm-item,
.menu-item {

  padding-right: 15px;

} */
    .mobile-push-menu .dropdown-menu {
        min-width: 5rem !important;
    }
    .mobile-push-menu .dropdown-menu {
        right: 0 !important;
    }
    .random-article-right {
        display: block;
    }
    .random-article-right .mainArticalImg {
        width: 100%;
    }
    .random-article-detalis {
        margin-right: 0;
    }
    .article-circle img {
        height: 80%;
    }
    .article-info {
        padding: 10px;
    }
    .article-info-header h2 {
        font-size: 16px;
    }
    .timeToRead-article-info {
        font-size: 11px;
    }
    /* .likeAndShear img {
  width: 30px;
} */
    .article-title {
        margin-bottom: 20px;
    }
    .Article {
        margin-top: 80px;
    }
    .article-title,
    .random-article-right h2,
    .random-article-left h2 {
        font-size: 16px;
    }
    .Training .commingSoon-label div {
        width: 40px;
        height: 40px;
    }
    .MeasureStart ul li .TestBox {
        padding: 2px;
        margin: 13px 2px -75px;
        font-size: 12px;
    }
    .MeasureStart ul li .TestBox h4 {
        font-size: 14px;
    }
    .AllQuestions {
        padding: 5px;
    }

    .containerOuter .container {
        margin: 25px 7px 0;
    }

    .EmailSubs .buttonSub {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ReverseColsInMobile {
        display: flex !important;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap-reverse !important;
    }

    .ourServices-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content;
        line-height: 1.1; 
    }

    .slick-slide img {
        max-width: 100%!important;
        max-height: 245px!important;
        object-fit: contain;
        height: -webkit-fill-available;
    }

}

@media only screen and (max-width: 375px) {
    .training-img p {
        font-size: 9px;
    }
    .books .css-1qzevvg {
        width: 80% !important;
    }
    .recommendations .container {
        padding: 0 !important;
    }
    .Training-landing-page {
        height: 540px;
    }
    .Training-landing-page-img {
        height: 500px;
    }
    .structure-training .personal-training .queries-info div {
        margin-right: 20%;
    }
    .RegisterCourse-socialMedia-socialMedia a i {
        font-size: 18px;
    }
    .RegisterCourse-socialMedia a {
        color: #ef8354;
        font-size: 13px;
    }
    .landing-details h2 {
        font-size: 30px;
    }
    .Training .commingSoon-label span {
        font-size: 11px;
    }
    .accordion-course .accordion > .card > .card-header {
        padding: 7px;
    }
    .accordion-course .accordion > .card > .card-header div h4 {
        font-size: 12px;
        margin: 0 5px;
    }
    .accordion-course .accordion > .card > .watch-done div span {
        font-size: 10px;
        margin: 0 8px;
    }
    .whyChooseUs-carousel-title {
        font-size: 8px;
        top: 5%;
        padding: 11%;
        left: 0%;
    }
    .CarouselWhyChooseUs .carousel,
    .WhyChooseUs .carousel {
        margin-top: 20px;
    }
    .CarouselWhyChooseUs .carousel-caption,
    .WhyChooseUs .carousel-caption {
        bottom: 2px;
    }
    .CarouselWhyChooseUs .carousel-caption p,
    .WhyChooseUs .carousel-caption p {
        font-size: 9px;
    }
    .carousel-indicators {
        bottom: -7px !important;
    }
    .WhyChooseUs .contactUs-title h3,
    .WhyChooseUs .ourServices-title h3 {
        font-size: 19px;
    }
    .ourClients {
        height: 150px;
    }
    .WhyChooseUs .ourClients .carousel {
        margin-top: 0px;
    }
    .ourClients-workExperience img,
    .ourClients-carousle-item img {
        width: 45px;
    }
    .ourClients .ourServices-title {
        margin-bottom: 10px;
    }
    .Article .carousel-control-next {
        left: 21%;
    }
    .numImgLike {
        width: 35px;
    }
    .likeAndShear .numImgLike img {
        width: 20px;
    }
    .likeAndShear .shareDiv {
        width: 35px;
        height: 36px;
    }
    .likeAndShear img {
        width: 20px;
    }
    .timeToRead-article-info {
        font-size: 10px;
    }
    .modal-title {
        font-size: 16px;
    }
    .MeasureStart ul li .TestBox h4 {
        font-size: 14px;
    }

    .AllQuestions {
        padding: 5px;
    }

    .containerOuter .container {
        margin: 25px 7px 0;
    }

    .EmailSubs .buttonSub {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ReverseColsInMobile {
        display: flex !important;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap-reverse !important;
    }

    .ourServices-title h3 { 
        font-size: 20px; 
        width: -webkit-fit-content; 
        width: -moz-fit-content; 
        width: fit-content;
        line-height: 1.1; 
    }


}

@media only screen and (max-width: 320px) {
    .ReverseColsInMobile {
        display: flex !important;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap-reverse !important;
    }
}

.bg-warning {
    background-color: #4f5d75 !important;
}

